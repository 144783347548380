<div class="DivBodyContainer" (scroll)="scrolled($event)" appCheckDevTools>
    <div id="divBodyHomeMenu">
        <nav id="navigationMainHome" [ngClass]="_booleanMenuMini ? 'NavHeaderMinimize' : 'NavHeaderMaximize'"  (click)="!_booleanMenuMaximize && minimizeMenu(true)">
			<div class="DivNavigationHeader">
				<img class="ImageLogoBRINS" (click)="goToHome()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo Header">
			</div>
            <div class="DivNavigationList">
				<ul>
					<ng-container *ngIf="!_booleanMenuMini && _booleanMenuMaximize">
						<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.AboutUs) }">
							<a class="ANavigationMenu" (click)="goToAbout()">Tentang Kami</a>
							<hr class="DivNavigationBottomLine">
							<div class="DivNavigationDetail">
								<ul>
									<li>
										<a class="ANavigationMenuList" (click)="goToCorporateValues()">Nilai Perusahaan</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToCompanyProfile()">Profil Perusahaan</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToOrganizationAndStructure()">Struktur Organisasi</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToAward()">Penghargaan</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToPartner()">Mitra</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToContactAndLocation()">Kontak & Lokasi</a>
									</li>
								</ul>
							</div>
						</li>
						<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.SocialValue) }">
							<a class="ANavigationMenu" (click)="this.goToSocialValueGCG()">Nilai Sosial</a>
							<hr class="DivNavigationBottomLine">
						</li>
						<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.EconomicValue) }">
							<a class="ANavigationMenu" (click)="this.goToEconomicValue()">Nilai Ekonomi</a>
							<hr class="DivNavigationBottomLine">
						</li>
						<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.BRINSUpdate) }">
							<a class="ANavigationMenu" (click)="this.goToBRINSUpdate()">BRINS Update</a>
							<hr class="DivNavigationBottomLine">
							<div class="DivNavigationDetail">
								<ul>
									<li>
										<a class="ANavigationMenuList" (click)="goToBRINSUpdateNewsFeeds()">Berita Terkini</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToBRINSUpdateCommunity()">Komunitas</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToBRINSUpdateTestimoni()">Testimoni</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToBRINSUpdateFinancialLiteracy()">Literasi Keuangan</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToBRINSUpdateBRINSEditorial()">BRINS Editorial</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.CustomerCare) }">
							<a class="ANavigationMenu" (click)="goToCustomerCare()">Layanan Pelanggan</a>
							<hr class="DivNavigationBottomLine">
							<div class="DivNavigationDetail">
								<ul>
									<li>
										<a class="ANavigationMenuList" (click)="goToCustomerCareSubmissionClaim()">Pengajuan Klaim</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="goToWorkshop()">Bengkel Rekanan</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="this.goToCustomerCareTrackingClaim()">Riwayat Klaim</a>
									</li>
									<!-- <li>
										<a class="ANavigationMenuList" (click)="goToTrackingClaim()">Lacak Klaim</a>
									</li> -->
								</ul>
							</div>
						</li>
						<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Insurtech) }">
							<a class="ANavigationMenu" (click)="goToInsurtech()">Insurtech</a>
							<hr class="DivNavigationBottomLine">
						</li>
						<li class="ListNavigationMenu">
							<a class="ANavigationMenu" *ngIf="_modelUserSignIn.Token === undefined" (click)="this.signIn()"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_profile.svg"></a>
							<a class="ANavigationMenu" *ngIf="_modelUserSignIn.Token !== undefined" (click)="goToProfile()"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_profile.svg"></a>
							<hr class="DivNavigationBottomLine">
							<div class="DivNavigationDetail" id="divNavigationProfile">
								<ul>
									<li *ngIf="_modelUserSignIn.Token === undefined">
										<a class="ANavigationMenuList" (click)="signIn()">Masuk</a>
									</li>
									<li *ngIf="_modelUserSignIn.Token !== undefined">
										<a class="ANavigationMenuList" (click)="signOut()">Keluar</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="ListNavigationMenu">
							<div class="DivBullet" *ngIf="_modelDashboardNotification.UnseenNotification === true"></div>
							<a class="ANavigationMenu" (click)="goToNotification()"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_notification.svg"></a>
							<hr class="DivNavigationBottomLine">
						</li>
						<li class="ListIconNavigation">
							<select class="SelectNavigationLanguage">
								<option>ID</option>
							</select>
						</li>
					</ng-container>
					<ng-container *ngIf="_booleanMenuMini || !_booleanMenuMaximize">
						<li class="ListIconNavigation">
							<img id="imageIconHamburger" (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_hamburger_menu.svg">
						</li>
					</ng-container>
				</ul>
            </div>
        </nav>

		<nav id="navigationAside" class="NavMenuOverlay" [ngClass]="_booleanMenuBarMinimize ? 'NavMenuMinimizeOverlay' : 'NavMenuMinimizeNoOverlay'" >
			<div class="DivMenuHeader">
				<img id="imageLogoDigital" (click)="goToHome(); minimizeMenu(false)" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo Header Digital">
				<img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg">
			</div>

			<ul *ngIf="!_booleanMenuMaximize" class="UlMenuMinimize ListNavigation">
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="this.goToHome(); minimizeMenu(false)">
					<label>Beranda</label>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerAbousUs">Tentang Kami</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAbousUs" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
					<ul class="ListSubNavigation">
						<li class="ListItemSubNavigation" (click)="goToAbout(); minimizeMenu(false);">
							<label>Tentang Kami</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToCorporateValues(); minimizeMenu(false)">
							<label>Nilai Perusahaan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToCompanyProfile(); minimizeMenu(false)">
							<label>Profil Perusahaan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToOrganizationAndStructure(); minimizeMenu(false)">
							<label>Struktur Organisasi</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToAward(); minimizeMenu(false)">
							<label>Penghargaan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToPartner(); minimizeMenu(false)">
							<label>Mitra</label>
						</li>
						<li class="ListItemSubNavigation" (click)="goToContactAndLocation(); minimizeMenu(false)">
							<label>Kontak & Lokasi</label>
						</li>
					</ul>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.SocialValue) }" (click)="this.goToSocialValueGCG(); minimizeMenu(false)">
					<label>Nilai Sosial</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.EconomicValue) }" (click)="this.goToEconomicValue(); minimizeMenu(false)">
					<label>Nilai Ekonomi</label>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerBRINSUpdate">BRINS Update</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBRINSUpdate" [checked]="this._booleanNavigationItem2" [(ngModel)]="_booleanNavigationItem2">
					<ul class="ListSubNavigation">
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdate(); minimizeMenu(false)">
							<label>BRINS Update</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdateNewsFeeds(); minimizeMenu(false)">
							<label>Berita Terkini</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdateCommunity(); minimizeMenu(false)">
							<label>Komunitas</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdateTestimoni(); minimizeMenu(false)">
							<label>Testimoni</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdateFinancialLiteracy(); minimizeMenu(false)">
							<label>Literasi Keuangan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToBRINSUpdateBRINSEditorial(); minimizeMenu(false)">
							<label>BRINS Editorial</label>
						</li>
					</ul>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerCustomerCare">Layanan Pelanggan</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCustomerCare" [checked]="this._booleanNavigationItem3" [(ngModel)]="_booleanNavigationItem3">
					<ul class="ListSubNavigation">
						<li class="ListItemSubNavigation" (click)="this.goToCustomerCare(); minimizeMenu(false)">
							<label>Layanan Pelanggan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToCustomerCareSubmissionClaim(); minimizeMenu(false)">
							<label>Pengajuan Klaim</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToWorkshop(); minimizeMenu(false)">
							<label>Bengkel Rekanan</label>
						</li>
						<li class="ListItemSubNavigation" (click)="this.goToCustomerCareTrackingClaim(); minimizeMenu(false)">
							<label>Riwayat Klaim</label>
						</li>
						<!-- <li class="ListItemSubNavigation" (click)="this.goToTrackingClaim(); minimizeMenu(false)">
							<label>Lacak Claim</label>
						</li> -->
					</ul>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Insurtech) }" (click)="this.goToInsurtech(); minimizeMenu(false)">
					<label>Insurtech</label>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerProfil">Profil</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerProfil" [checked]="this._booleanNavigationItem4" [(ngModel)]="_booleanNavigationItem4">
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
						<li class="ListItemSubNavigation" (click)="goToProfile(); minimizeMenu(false)">
							<label>Profil</label>
						</li>
						<li class="ListItemSubNavigation" (click)="signOut(); minimizeMenu(false)">
							<label>Keluar</label>
						</li>
					</ul>
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName === null || _modelUserSignIn.FirstName === undefined || _modelUserSignIn.FirstName === ''">
						<li class="ListItemSubNavigation" (click)="signIn(); minimizeMenu(false)">
							<label>Masuk</label>
						</li>
					</ul>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Notification) }" (click)="this.goToNotification(); minimizeMenu(false)">
					<label>Notifikasi</label>
				</li>
			</ul>
		</nav>
    </div>

    <div id="divMainDashboard">
		<router-outlet></router-outlet>
	</div>
</div>
