//#region CLASS

export class OccupationModel
{
	/*
		Attribute - START
		Description : Attribute for occupation model, tight to product personal accident.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.	        	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;

	/* Attribute - END */
}

//#endregion