import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_PILLARBUSSINES_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { AwardModel } from "src/app/models/award.model";
import { AwardGalleryModel } from "src/app/models/awardgallery.model";
import { TableModel } from "src/app/models/bases/table.model";
import { BRINSUpdateModel } from "src/app/models/brinsupdate.model";
import { CommunityModel } from "src/app/models/community.model";
import { CommunityGalleryModel } from "src/app/models/communitygallery.model";
import { ContentModel } from "src/app/models/content.model";
import { DiscountModel } from "src/app/models/discount.model";
import { DiscountGalleryModel } from "src/app/models/discountgallery.model";
import { FinancialLiteracyModel } from "src/app/models/financialliteracy.model";
import { FinancialLiteracyGalleryModel } from "src/app/models/financialliteracygallery.model";
import { NewsFeedModel } from "src/app/models/newsfeed.model";
import { NewsFeedGalleryModel } from "src/app/models/newsfeedgallery.model";
import { ResponseModel } from "src/app/models/response.model";
import { TestimonyModel } from "src/app/models/testimony.model";
import { TestimonyGalleryModel } from "src/app/models/testimonygallery.model";
import { BRINSUpdateService } from "src/app/services/brinsupdate.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

@Component
(
	{
		selector: "app-landing",
		templateUrl: "./landing.component.html",
		styleUrls: ["./landing.component.sass"]
	}
)
export class LandingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringPathBackgroundImage: string;
	public _stringPathDashboardImage: string;
	public _stringAboutBRINSDescription: string;
	public _stringInputValue: string;

	public _numberIndexDashboard: number;
	public _numberIndexCorporate: number;
	public _numberIndexAwards: number;
	public _numberIndexBRINSUpdate: number;
	public _numberIndexBRIGroup: number;
	public _numberIndexBRIGroupPrevious: number;
	public _numberIndexBRIGroupNext: number;
	public _numberConstant = NumberConstant;
	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _booleanMinimizeMenu: boolean;

	public _modelTable: TableModel;
	public _modelBrinsUpdate: BRINSUpdateModel;
	public _modelBrinsUpdateCarousel: BRINSUpdateModel;

	public _arrayModelHero: Array<ContentModel>;
	public _arrayModelPillars: Array<ContentModel>;
	public _arrayModelPartners: Array<ContentModel>;
	public _arrayModelAwards: Array<ContentModel>;
	public _arrayModelBRIGroup: Array<ContentModel>;
	public _arrayModelContentTemporary: Array<ContentModel>;
	public _arrayModelBrinsUpdate: Array<BRINSUpdateModel>;

	public _dateNow: Date;

	public _intervalIDSection1: any;
	public _intervalIDSection2: any;
	public _intervalIDSection3: any;
	public _intervalIDSection4: any;

	public _innerWidth: any;

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	private _enumPillarBusinessType = ENUM_PILLARBUSSINES_TYPE;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _serviceBrinsUpdate: BRINSUpdateService)
	{
		super(serviceSession, router);

		this._stringPathBackgroundImage = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/background_home.svg";
		this._stringPathDashboardImage = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/background_home.svg";
		this._stringInputValue = "";
		this._numberIndexDashboard = 0;
		this._numberIndexCorporate = 0;
		this._numberIndexAwards = 0;
		this._numberIndexBRINSUpdate = 0;
		this._numberIndexBRIGroup = 0;
		this._numberIndexBRIGroupPrevious = 2;
		this._numberIndexBRIGroupNext = 1;
		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;
		this._booleanMinimizeMenu = false;
		this._innerWidth = window.innerWidth;
		this._dateNow = new Date();
		// eslint-disable-next-line max-len
		this._stringAboutBRINSDescription = "Berdiri lebih dari 30 tahun, BRI Insurance berkomitmen memberikan perlindungan asuransi umum melalui beragam produk terpercaya yang didukung inovasi teknologi, GCG yang prudent dan human capital unggul.";
		this._arrayModelContentTemporary = [];
		this._modelTable = new TableModel();
		this._modelBrinsUpdate = new BRINSUpdateModel();
		this._modelBrinsUpdateCarousel = new BRINSUpdateModel();
		this._arrayModelBrinsUpdate = [];
		this._arrayModelHero =
		[
			// {
			// 	ID: 0,
			// 	Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide7.png",
			// 	Title: "",
			// 	// Detail: "Selengkapnya",
			// },
			{
				ID: 0,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide6.png",
				Title: "",
				// Detail: "Selengkapnya",
			},
			{
				ID: 1,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide1.jpg",
				Title: "",
				// Detail: "Selengkapnya",
			},
			{
				ID: 2,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide2.jpg",
				Title: "",
				// Detail: "Selengkapnya",
			},
			{
				ID: 3,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide3.jpg",
				Title: "Bertumbuh Bersama Majukan & Lindungi Ribuan UMKM Indonesia",
				// Detail: "Selengkapnya",
			},
			{
				ID: 4,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide4.jpg",
				Title: "",
				// Detail: "Selengkapnya",
			},
			{
				ID: 5,
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_slide5.jpg",
				Title: "",
				// Detail: "Selengkapnya",
			},
		];

		this._arrayModelPillars =
		[
			{
				ID: 0,
				Name: "Wholesale",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/pillar/photo_wholesale.png",
				Path2: environment.URL_BRINS_WHOLESALE_WEB+"",
				Title: "Business Pillars1",
				Description: "Membangun ekosistem bisnis korporasi yang kuat dengan jaringan yang mendukung.",
				Detail: "Selengkapnya",
			},
			{
				ID: 1,
				Name: "Ritel",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/pillar/photo_ritel.png",
				Path2: environment.URL_BRINS_RETAIL_WEB+"",
				Title: "Business Pillars2",
				Description: "Optimalisasi bisnis ritel yang menyeluruh.",
				Detail: "Selengkapnya",
			},
			{
				ID: 2,
				Name: "Mikro",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/pillar/photo_mikro.png",
				Path2: environment.URL_BRINS_MICRO_WEB+"",
				Title: "Business Pillars3",
				Description: "Ekspolarasi potensi asuransi mikro yang didukung dengan karakteristik Sederhana, Mudah, Ekonomis, dan Segera (SMES).",
				Detail: "Selengkapnya",
			},
			{
				ID: 3,
				Name: "Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/pillar/photo_syariah.png",
				Path2: environment.URL_BRINS_SYARIAH_WEB+"",
				Title: "Business Pillars4",
				Description: "Menciptakan bisnis syariah dengan prinsip yang aman, transparan, dan tolong menolong.",
				Detail: "Selengkapnya",
			}
		];

		this._arrayModelPartners =
		[
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_broom.svg",
				Title: "Broom",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_mb.svg",
				Title: "Mitra Bukalapak",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_jagain.svg",
				Title: "Jagain.com",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_pasarpolis.png",
				Title: "Pasarpolis",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_lifepal.svg",
				Title: "Lifepal",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_fr.png",
				Title: "Futuready",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_agenbrilink.svg",
				Title: "AgenBRILink",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_asuransiku.png",
				Title: "Asuransiku.id",
			},
			{
				Path: "../../assets/logos/sakina/partners/digital/logo_pigijo.png",
				Title: "Pigijo",
			},
			{
				Title: "PT. BRI Dana reksa",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_danareksa.svg",
			},
			{
				Title: "PT. Bank BPD",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_bpd.png",
			},
			{
				Title: "PT. PKSS",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_pkss.png",
			},
			{
				Title: "PT .BRISMA",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_brisma.png",
			},
			{
				Title: "PT. BRI",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_bri.svg",
			},
			{
				Title: "PT. BRIF",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_finance.svg",
			},
			// {
			// 	Title: "PT. Antero",
			// Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_antero.png",
			// },
			{
				Title: "PT. PLH",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_plh.png",
			},
			{
				Title: "PT. BANK RAYA INDONESIA",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_raya.svg",
			},
			{
				Title: "PT. BRIKS",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_briks.png",
			},
			{
				Title: "PT. BRILife",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_life.svg",
			},
			{
				Title: "PT. YKP",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_ykp.png",
			},
			{
				Title: "PT. Dana Pensiun",
				Path: "../../assets/logos/sakina/partners/RetailAndMikro/logo_dapen.png",
			},
			{
				Title: "PT. Perhutani",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_perhutani.svg",
			},
			{
				Title: "PT. SKK Migas",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_migas.svg",
			},
			{
				Title: "PT. Semen Indonesia",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_semen.svg",
			},
			{
				Title: "PT. PLN",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_pln.svg",
			},
			{
				Title: "PT. Pupuk Indonesia",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_pupuk.png",
			},
			{
				Title: "PT. Pelindo IV",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_pelindo.png",
			},
			{
				Title: "PT. Taspen",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_taspen.svg",
			},
			{
				Title: "PT. Kereta Api Logistic",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_perhutani.svg",
			},
			{
				Title: "PT. Pegadaian",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_pegadaian.svg",
			},
			{
				Title: "PT. Dirgantara",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_dirgantara.png",
			},
			{
				Title: "PT. Air Nav",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_arinav.png",
			},
			{
				Title: "PT. Pertamina",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_pertamina.svg",
			},
			{
				Title: "PT. Bio Farma",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_biofarma.png",
			},
			{
				Title: "PT. Angkasa Pura Support",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_angkasa.png",
			},
			{
				Title: "PT. LEN Industri Bandung",
				Path: "../../assets/logos/sakina/partners/BUMN/logo_len.png",
			},
			{
				Title: "AON",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_aon_benfield.png",
			},
			{
				Title: "ARB",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_arb.png",
			},
			{
				Title: "Asrinda",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_asrinda.png",
			},
			{
				Title: "BOARe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_boare.png",
			},
			{
				Title: "FLRr",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_flre.png",
			},
			{
				Title: "IBSRr",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_ibs.png",
			},
			{
				Title: "JakRe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_jakre.png",
			},
			{
				Title: "LinksRe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_linksre.png",
			},
			{
				Title: "MARSHRe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_marsh.png",
			},
			{
				Title: "MegaRe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_megare.png",
			},
			{
				Title: "SIMASRE",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_simasre.png",
			},
			{
				Title: "TrinityRe",
				Path: "../../assets/logos/sakina/partners/reinsurancebroker/logos_trinity.png",
			},
		];
		this._arrayModelAwards =
		[
			{
				Description: "Indonesia Public Relation Award 2024 yang diselenggarakan oleh IPRA",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_1a.jpg",
				Path2: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_1b.jpg",
				Path3: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_1c.jpg",
				NowDate: "25",
				NowMonth: "Jan",
				NowYear: "2024",
			},
			{
				Description: "Trusted Company Based on Corporate Governance Perception Index yang diselenggarakan oleh CGPI",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_2a.jpg",
				Path2: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_2b.jpg",
				Path3: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_2c.jpg",
				NowDate: "20",
				NowMonth: "Des",
				NowYear: "2023",
			},
			{
				Description: "Indonesia Most Trusted Companies Award yang diselenggarakan oleh CGPI",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_3a.jpg",
				Path2: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_3b.jpg",
				Path3: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_3c.jpg",
				NowDate: "20",
				NowMonth: "Des",
				NowYear: "2023",
			},
			{
				Description: "Penghargaan Kategori Pertumbuhan Premi Sesi 2022 Terbesar yang diselenggarakan oleh MAIPARK",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_4a.jpg",
				Path2: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_4b.jpg",
				Path3: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_4c.jpg",
				NowDate: "28",
				NowMonth: "Nov",
				NowYear: "2023",
			},
			{
				Description: "Best CFO Category General Insurance - Sony Harsono yang diselenggarakan oleh The Finance",
				Path: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_5a.jpg",
				Path2: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_5b.jpg",
				Path3: environment.URL_BROADCAST_API+"/Website/assets/photos/sakina/brins/award/award/photo_bubble_5c.jpg",
				NowDate: "14",
				NowMonth: "Nov",
				NowYear: "2023",
			},
		];
		this._arrayModelBRIGroup =
		[
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_bri.svg",
				Path2: "https://bri.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_danareksa.svg",
				Path2: "https://www.bridanareksasekuritas.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_finance.svg",
				Path2: "http://www.brifinance.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_life.svg",
				Path2: "https://brilife.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_raya.svg",
				Path2: "https://bankraya.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_remittance.svg",
				Path2: "https://www.brchk.com/main/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_ventures.svg",
				Path2: "https://briventures.id/#LatestNews-link",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_pegadaian.svg",
				Path2: "https://www.pegadaian.co.id/",
			},
			{
				Path : "../../assets/logos/sakina/partners/BRIGroup/logo_pnm.png",
				Path2: "https://www.pnm.co.id/",
			},
		];


		this.setNextGallery();
		this.getScreenSize();
	}

	//#endregion


	//#region LISTENER

	@HostListener("window:resize", ["$event"])
	// eslint-disable-next-line no-unused-vars
	getScreenSize(event?): void
	{
		this._numberPixelHeight = window.innerHeight;
		this._numberPixelWidth = window.innerWidth;

		if(this._numberPixelWidth <= 800)
		{
			// this._arrayModelHero[0].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_spinoffsyariah_800px.png";
			this._arrayModelHero[0].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_ultahbrins_800px.png";
			this._arrayModelHero[1].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_asmik_800px.jpg";
			this._arrayModelHero[2].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_anti_800px.png";
			this._arrayModelHero[3].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_harian_800px.jpg";
			this._arrayModelHero[4].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_syariah_800px.jpg";
		}
		else
		{

		}

		if(this._numberPixelWidth <= 490)
		{
			// this._arrayModelHero[0].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_spinoffsyariah_490px.png";
			this._arrayModelHero[0].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_ultahbrins_490px.png";
			this._arrayModelHero[1].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_asmik_490px.jpg";
			this._arrayModelHero[2].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_anti_490px.png";
			this._arrayModelHero[3].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_harian_490px.jpg";
			this._arrayModelHero[4].Path = environment.URL_BROADCAST_API+"/Website/assets/background/sakina/brins/hero/header_syariah_490px.jpg";
		}
		else
		{

		}
	}

	//#endregion


	//#region INITIALIZATION

	ngOnInit(): void
	{
		this._numberIndexBRIGroupPrevious = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		this.setIntervalPartner();
		// this.setCarouselSection1();
		this.showContent2(0);
		this.setCarouselSection2();
		// this._functionUserInterface.setAnimationSVGOnScroll();
		this.callSelectBRINSUpdateByAttributes(this);
	}

	ngOnDestroy(): void
	{
		clearInterval(this._intervalIDSection1);
		clearInterval(this._intervalIDSection2);
		clearInterval(this._intervalIDSection3);
		clearInterval(this._intervalIDSection4);
	}

	//#endregion


	//#region FUNCTION

	showContent1(numberIndex: number): void
	{
		this._numberIndexDashboard = numberIndex;
		this.setBackgroundContentDashboard();
	}

	showContent2(numberIndex: number): void
	{
		const elementImage = document.querySelectorAll<HTMLElement>(".ImageClipSVG");

		if(numberIndex === 0)
		{
			elementImage[0].style.opacity = "1";
			elementImage[0].classList.add("ImageCurrent");
			elementImage[1].style.opacity = "0";
			elementImage[2].style.opacity = "0";
			elementImage[3].style.opacity = "0";
		}
		else if (numberIndex === 1)
		{
			elementImage[0].style.opacity = "0";
			elementImage[1].style.opacity = "1";
			elementImage[1].classList.add("ImageCurrent");
			elementImage[2].style.opacity = "0";
			elementImage[3].style.opacity = "0";
		}
		else if (numberIndex === 2)
		{
			elementImage[0].style.opacity = "0";
			elementImage[1].style.opacity = "0";
			elementImage[2].style.opacity = "1";
			elementImage[2].classList.add("ImageCurrent");
			elementImage[3].style.opacity = "0";
		}
		else if (numberIndex === 3)
		{
			elementImage[0].style.opacity = "0";
			elementImage[1].style.opacity = "0";
			elementImage[2].style.opacity = "0";
			elementImage[3].style.opacity = "1";
			elementImage[3].classList.add("ImageCurrent");
		}

		this._numberIndexCorporate = numberIndex;
	}

	showContent3(numberIndex: number): void
	{
		this._numberIndexAwards = numberIndex;
	}

	minimizeMenu(booleanMenu: boolean): void
	{
		this._booleanMinimizeMenu = booleanMenu;
	}

	setBackgroundContentDashboard(): void
	{
		const modelContent: ContentModel | undefined = this._arrayModelHero.find(content => content.ID === this._numberIndexDashboard);

		if(modelContent !== undefined)
		{
			if(modelContent.Path)
			{
				this._stringPathDashboardImage = modelContent.Path;
			}
			else
			{
				this._stringPathDashboardImage = StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			this._stringPathDashboardImage = StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getPathDashboardImage(numberIndex: number): string
	{
		const modelContent: ContentModel | undefined = this._arrayModelHero.find(content => content.ID === numberIndex);
		if(modelContent !== undefined)
		{
			if(modelContent.Path)
			{
				return this._stringPathDashboardImage = modelContent.Path;
			}
			else
			{
				return this._stringPathDashboardImage = StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return this._stringPathDashboardImage = StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	public getThemeColorPillar(numberIndex: number): string
	{
		if(numberIndex == null && numberIndex === undefined)
		{
			return "";
		}
		else
		{
			if(numberIndex === this._enumPillarBusinessType.Corporate)
			{
				return "headerPillarCorporate";
			}
			else if(numberIndex === this._enumPillarBusinessType.Ritel)
			{
				return "headerPillarRitel";
			}
			else if(numberIndex === this._enumPillarBusinessType.Mikro)
			{
				return "headerPillarMikro";
			}
			else if(numberIndex === this._enumPillarBusinessType.Syariah)
			{
				return "headerPillarSyariah";
			}
			else
			{
				return "";
			}
		}
	}

	setIndexContentBRINSWebsite(numberIndex: number): void
	{
		this._modelBrinsUpdateCarousel = this._arrayModelBrinsUpdate[numberIndex];
		if(numberIndex > this._arrayModelBrinsUpdate.length)
		{
			this._numberIndexBRINSUpdate = 0;
		}
		else
		{
			this._numberIndexBRINSUpdate = numberIndex;
		}
	}

	setIndexContentBRIGroup(numberIndex: number): void
	{
		if(numberIndex > this._arrayModelBRIGroup.length)
		{
			this._numberIndexBRIGroup = 0;
		}
		else
		{
			this._numberIndexBRIGroup = numberIndex;
		}
	}

	getImagePathHome(numberIndex: number): any
	{
		let stringPath: string | undefined = this._arrayModelHero[numberIndex].Path || undefined;
		if(stringPath === null && stringPath === undefined && stringPath === "")
		{
			stringPath = this._arrayModelHero[numberIndex].Path;
		}
		return {"background-image": "url(" + stringPath + ")"};
	}

	downloadFile(stringPath: string): void
	{
		window.open(stringPath, "_blank");
	}

	setNextGallery(): void
	{
		this._intervalIDSection1 = setInterval(() => { this.getNextGallery(); }, this._numberConstant.NUMBER_INTERVAL_THREE_SECONDS);
	}

	getNextGallery(): void
	{
		if(this._numberIndexBRIGroupNext > (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_TWO))
		{
			this._numberIndexBRIGroupNext = 0;
			this._numberIndexBRIGroup = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroupPrevious = (this._numberIndexBRIGroupPrevious + this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
		else if(this._numberIndexBRIGroup > (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_TWO))
		{
			this._numberIndexBRIGroupNext = this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
			this._numberIndexBRIGroup = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			this._numberIndexBRIGroupPrevious = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
		else
		{
			this._numberIndexBRIGroup = (this._numberIndexBRIGroup + this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroupNext = (this._numberIndexBRIGroupNext + this._numberConstant.NUMBER_COMPARE_VALUE_ONE);


			if(this._numberIndexBRIGroupPrevious > (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_TWO))
			{
				this._numberIndexBRIGroupPrevious = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			}
			else
			{
				this._numberIndexBRIGroupPrevious = (this._numberIndexBRIGroupPrevious + this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			}
		}
	}

	getPreviousGallery(): void
	{
		if(this._numberIndexBRIGroupPrevious === this._numberConstant.NUMBER_COMPARE_VALUE_ZERO)
		{
			this._numberIndexBRIGroupNext = this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
			this._numberIndexBRIGroup = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			this._numberIndexBRIGroupPrevious = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
		else if(this._numberIndexBRIGroup === this._numberConstant.NUMBER_COMPARE_VALUE_ZERO)
		{
			this._numberIndexBRIGroupNext = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			this._numberIndexBRIGroup = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroupPrevious = (this._numberIndexBRIGroupPrevious - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
		else if(this._numberIndexBRIGroupNext === this._numberConstant.NUMBER_COMPARE_VALUE_ZERO)
		{
			this._numberIndexBRIGroupNext = (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroup = (this._numberIndexBRIGroup - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroupPrevious = (this._numberIndexBRIGroupPrevious - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
		else
		{
			this._numberIndexBRIGroup = (this._numberIndexBRIGroup - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			this._numberIndexBRIGroupNext = (this._numberIndexBRIGroupNext - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);

			if(this._numberIndexBRIGroupPrevious > (this._arrayModelBRIGroup.length - this._numberConstant.NUMBER_COMPARE_VALUE_TWO))
			{
				this._numberIndexBRIGroupPrevious = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			}
			else
			{
				this._numberIndexBRIGroupPrevious = (this._numberIndexBRIGroupPrevious - this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
			}
		}
	}

	setIntervalPartner(): void
	{
		this._intervalIDSection2 = setInterval(() => { this.intervalAwards(); }, this._numberConstant.NUMBER_INTERVAL_THREE_SECONDS);
	}

	setCarouselSection1(): void
	{
		const slidesContent = document.querySelectorAll(".DivContentSection");

		const nextSlide = () =>
		{
			const currentSlide = document.querySelector(".DivContentSectionActive");

			if (currentSlide?.classList !== undefined && currentSlide?.classList !== null)
			{
				currentSlide.classList.remove("DivContentSectionActive");

				if (currentSlide.nextElementSibling)
				{
					this._numberIndexDashboard += 1;
					if (currentSlide.nextElementSibling.classList !== undefined && currentSlide.nextElementSibling.classList !== null)
					{
						currentSlide.nextElementSibling.classList.add("DivContentSectionActive");
					}
					else
					{

					}
				}
				else
				{
					if (slidesContent !== undefined && slidesContent !== null)
					{
						this._numberIndexDashboard = 0;

						if (slidesContent[0]?.classList !== undefined && slidesContent[0]?.classList !== null)
						{
							slidesContent[0].classList.add("DivContentSectionActive");
						}
						else
						{

						}
					}
					else
					{

					}
				}

				setTimeout(() => currentSlide.classList.remove("DivContentSectionActive"));
			}
			else
			{

			}
		};

		this._intervalIDSection3 = setInterval(nextSlide, this._numberConstant.NUMBER_INTERVAL_FIVE_SECONDS);
	}

	setCarouselSection2(): void
	{
		const slidesContent2 = document.querySelectorAll(".ImageClipSVG");

		const nextSlide = () =>
		{
			const currentSlide = document.querySelector(".ImageCurrent");

			currentSlide?.classList.remove("ImageCurrent");

			if (currentSlide?.nextElementSibling)
			{
				this._numberIndexCorporate += 1;
				this.showContent2(this._numberIndexCorporate);
				currentSlide.nextElementSibling.classList.add("ImageCurrent");
			}
			else
			{
				if (slidesContent2 !== undefined && slidesContent2 !== null)
				{
					this._numberIndexCorporate = 0;
					this.showContent2(this._numberIndexCorporate);
					if (slidesContent2[0].classList !== undefined && slidesContent2[0].classList !== null)
					{
						slidesContent2[0].classList.add("ImageCurrent");
					}
					else
					{

					}
				}
				else
				{

				}
			}

			setTimeout(() => currentSlide?.classList.remove("ImageCurrent"));
		};

		this._intervalIDSection4 = setInterval(nextSlide, this._numberConstant.NUMBER_INTERVAL_FIVE_SECONDS);
	}

	intervalAwards(): void
	{
		if(this._numberIndexAwards === (this._arrayModelAwards.length - this._numberConstant.NUMBER_COMPARE_VALUE_ONE))
		{
			this._numberIndexAwards = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		}
		else
		{
			this._numberIndexAwards = (this._numberIndexAwards + this._numberConstant.NUMBER_COMPARE_VALUE_ONE);
		}
	}

	scrollToID(el: HTMLElement): void
	{
		el.scrollIntoView();
	}

	changeInnerWidth(): void
	{

	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	getImageFromProductURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}


	getImageFromBroadcastURLForBrinsUpdate(modelBrinsUpdate: BRINSUpdateModel): string | null
	{
		if(modelBrinsUpdate.modelNewsFeed != null || modelBrinsUpdate.modelNewsFeed !== undefined)
		{
			const modelNewsFeeds: NewsFeedModel = modelBrinsUpdate.modelNewsFeed;
			if(modelNewsFeeds)
			{
				if(modelNewsFeeds.listModelNewsFeedGallery !== undefined && modelNewsFeeds.listModelNewsFeedGallery.length > 0)
				{
					const arrayModelNewsFeedGallery: Array<NewsFeedGalleryModel> = modelNewsFeeds.listModelNewsFeedGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelNewsFeedGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(modelBrinsUpdate.modelAward != null || modelBrinsUpdate.modelAward !== undefined)
		{
			const modelAward: AwardModel = modelBrinsUpdate.modelAward;
			if(modelAward)
			{
				if(modelAward.listModelAwardGallery !== undefined && modelAward.listModelAwardGallery.length > 0)
				{
					const arrayModelAwardGallery: Array<AwardGalleryModel> = modelAward.listModelAwardGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelAwardGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(modelBrinsUpdate.modelCommunity != null || modelBrinsUpdate.modelCommunity !== undefined)
		{
			const modelCommunity: CommunityModel = modelBrinsUpdate.modelCommunity;
			if(modelCommunity)
			{
				if(modelCommunity.listModelCommunityGallery !== undefined && modelCommunity.listModelCommunityGallery.length > 0)
				{
					const arrayModelCommunityGallery: Array<CommunityGalleryModel> = modelCommunity.listModelCommunityGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelCommunityGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(modelBrinsUpdate.modelDiscount != null || modelBrinsUpdate.modelDiscount !== undefined)
		{
			const modelDiscount: DiscountModel = modelBrinsUpdate.modelDiscount;
			if(modelDiscount)
			{
				if(modelDiscount.listModelDiscountGallery !== undefined && modelDiscount.listModelDiscountGallery.length > 0)
				{
					const arrayModelDiscountGallery: Array<DiscountGalleryModel> = modelDiscount.listModelDiscountGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelDiscountGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(modelBrinsUpdate.modelTestimony != null || modelBrinsUpdate.modelTestimony !== undefined)
		{
			const modelTestimony: TestimonyModel = modelBrinsUpdate.modelTestimony;
			if(modelTestimony)
			{
				if(modelTestimony.listModelTestimonyGallery !== undefined && modelTestimony.listModelTestimonyGallery.length > 0)
				{
					const arrayModelTestimonyGallery: Array<TestimonyGalleryModel> = modelTestimony.listModelTestimonyGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelTestimonyGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(modelBrinsUpdate.modelFinancialLiteracy != null || modelBrinsUpdate.modelFinancialLiteracy !== undefined)
		{
			const modelFinancialLiteracy: FinancialLiteracyModel = modelBrinsUpdate.modelFinancialLiteracy;
			if(modelFinancialLiteracy)
			{
				if(modelFinancialLiteracy.listModelFinancialLiteracyGallery !== undefined && modelFinancialLiteracy.listModelFinancialLiteracyGallery.length > 0)
				{
					const arrayModelFinancialLiteracyGallery: Array<FinancialLiteracyGalleryModel> = modelFinancialLiteracy.listModelFinancialLiteracyGallery;
					return this._pipeConvertToBroadcastURL.transform(arrayModelFinancialLiteracyGallery[0].URL);
				}
				else
				{
					return StringConstant.STRING_CHARACTER_DASH;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region WEBSERVICE

	private callSelectBRINSUpdateByAttributes(componentCurrent: LandingComponent): void
	{

		this._modelTable.RowPerPage = 7;
		this._modelTable.Search = JSON.stringify(this._modelBrinsUpdate);

		this._functionUserInterface.setLoadingProgress(1);

		this._serviceBrinsUpdate.selectBRINSUpdateByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBrinsUpdate = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelBrinsUpdateCarousel = componentCurrent._arrayModelBrinsUpdate[0];
							componentCurrent._modelTable.Result = undefined;
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelTable);
	}
	//#endregion


	// NAVIGATION - START

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
	}

	goToPartners(): void
	{
		this._router.navigate(["home", "about", "partnerandclient"]);
	}

	goToSearch(): void
	{
		this._router.navigate(["home", "search", this._stringInputValue]);
	}

	goToLink(stringURL: string): void
	{
		this._router.navigate(["home", "brinsupdate", "article", stringURL]);
	}

	public goToBRINSUpdate(): void
	{
		this._router.navigate(["home", "brinsupdate"]);
	}

	public goToPillar(stringURL: string): void
	{
		window.open(stringURL, "_blank");
	}

	// NAVIGATION - END
}
