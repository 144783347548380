//#region IMPORT

import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { TableModel } from "src/app/models/bases/table.model";
import { DiscountModel } from "src/app/models/discount.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserActivityHistoryModel } from "src/app/models/useractivityhistory.model";
import { BRINSUpdateService } from "src/app/services/brinsupdate.service";
import { DiscountService } from "src/app/services/discount.service";
import { NotificationService } from "src/app/services/notification.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.sass"]
})

//#endregion


//#region CLASS

export class NotificationComponent extends BaseAuthourizeComponent implements OnInit
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;


	public _modelUserActivityHistory: UserActivityHistoryModel;
	public _modelUserActivityHistoryRequest: UserActivityHistoryModel;
	public _modelDiscount: DiscountModel;
	public _modelTable: TableModel;

	public _arrayModelUserActivityHistory: Array<UserActivityHistoryModel>;
	public _arrayModelDiscount: Array<DiscountModel>;

	public _booleanActivity: boolean | null;
	public _booleanPromotion: boolean | null;

	public _pipeConvertToBroadcastURL: ConvertToBroadcastURL;

	public _environtment = environment;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceNotification: NotificationService, private _serviceBrinsUpdate: BRINSUpdateService, private _serviceDiscount: DiscountService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserActivityHistory = new UserActivityHistoryModel();
		this._modelUserActivityHistoryRequest = new UserActivityHistoryModel();
		this._modelDiscount = new DiscountModel();
		this._modelTable = new TableModel();

		this._arrayModelUserActivityHistory = [];
		this._arrayModelDiscount = [];

		this._booleanActivity = null;
		this._booleanPromotion = null;

		this._pipeConvertToBroadcastURL = new ConvertToBroadcastURL();
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		this.callSelectNotificationActivity();
	}

	//#endregion


	//#region WEB SERVICE

	callSelectUserActivityHistory(componentCurrent: NotificationComponent): void
	{
		const response: ResponseModel = new ResponseModel();
		this._modelTable.RowPerPage = 100;
		this._functionUserInterface.setLoadingProgress(1);

		if (this._modelUserSignIn.FirstName !== undefined)
		{
			this._serviceNotification.selectUserActivityHistory
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);
							if (componentCurrent._modelTable.Result !== undefined)
							{
								componentCurrent._arrayModelUserActivityHistory = JSON.parse(componentCurrent._modelTable.Result);
								componentCurrent._modelTable.Result = undefined;
								// componentCurrent._componentTableControlInclude.setButtonState();
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelTable);
		}
		else
		{
			response.MessageTitle = "Silakan login untuk melihat notification";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(response, () => { this._router.navigate(["signin"]); });
		}
	}

	callSelectDiscountByAttributesForCustomer(componentCurrent: NotificationComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._modelTable.RowPerPage = 100;
		this._modelTable.Search = JSON.stringify(this._modelDiscount);

		this._functionUserInterface.setLoadingProgress(1);

		this._serviceDiscount.selectDiscountByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelDiscount = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelTable);
	}

	callUpdateUserActivityHistorySeen(stringToken?: string): void
	{

		this._modelUserActivityHistoryRequest.Token = stringToken;

		const componentCurrent: NotificationComponent = this;
		this._functionUserInterface.setLoadingProgress(1);

		this._serviceNotification.updateUserActivityHistorySeen
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent.callSelectUserActivityHistory(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelUserActivityHistoryRequest);
	}

	//#endregion


	//#region FUNCTION

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	public callSelectNotificationActivity(): void
	{
		this._booleanActivity = true;
		this._booleanPromotion = false;
		this.callSelectUserActivityHistory(this);
	};

	public callSelectNotificationPromotion(): void
	{
		this._booleanActivity = false;
		this._booleanPromotion = true;
		this.callSelectDiscountByAttributesForCustomer(this);
	};

	//#endregion


	//#region GETTER

	getImage(stringURL): string
	{
		return this._pipeConvertToBroadcastURL.transform(stringURL);
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectUserActivityHistory(this);
	}

	//#endregion


}

//#endregion
