//#region CLASS

export class AverageIncomePerYearModel
{
	/*
		Attribute - START
		Description : Attribute for average income per year model.
		Author: Dimas Dandy.
		Created on : Tuesday, 13 August 2024	    	Updated on : .
		Created by : Dimas Dandy.						Updated by : .
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	Code?: string;
	Name?: string;
	Order?: number;

	/* Attribute - END */
}

//#endregion