/* eslint-disable no-shadow */
//#region ENUM

//#region STATE

/*
	ENUM_REGISTRATION_STATE - START
	Description : Set of constants for registration state.
	Author :  Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_REGISTRATION_STATE
{
	Success = 0,
	Verified = 1,
	NotVerified = 2
}

/* ENUM_REGISTRATION_STATE - END */

/*
		ENUM_VERIFICATION_STATE - START
		Description : Set of constants for registration state.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VERIFICATION_STATE
{
	Verified = 0,
	OnProcess = 1,
	NotVerified = 2,
	Rejected = 3
}

/* ENUM_VERIFICATION_STATE - END */

/*
		ENUM_GENERAL_STATE - START
		Description : Set of constants for state general.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENERAL_STATE
{
	Active = 0,
	Incative = 1
}

/* ENUM_GENERAL_STATE - END */

/*
		ENUM_RESPONSE_STATE - START
		Description : Enum for response state, this is used for validation response mostly, it will trigger the icon shown in the user interface.
		Author: Andri Septiawan.
		Created on :  Monday, 28 December 2020.             Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RESPONSE_STATE
{
	Success = 0,
	Warning = 1,
	Fail = 2,
	information = 3,
	Nothing = 4
}

/* ENUM_RESPONSE_STATE - END */

//#endregion


//#region TYPE

/*
		ENUM_GENDER_TYPE - START
		Description : Set of constants for gender type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENDER_TYPE
{
	Female = 0,
	Male = 1
}

/* ENUM_GENDER_TYPE - END */

/*
	ENUM_RELIGION_TYPE - START
	Description : Enum for religion state, these religion is based on Indonesia law.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_RELIGION_TYPE
{
	Islam = 0,
	Catholicism = 1,
	Christianity = 2,
	Hinduism = 3,
	Buddhism = 4,
	Confucianism = 5,

}

/*
		ENUM_BRANCH_TYPE - START
		Description : Set of constants for branch type, to define what branch type included in.
		Author : Billy Johannes.
		Created on : Sunday, 02 May 2020.          	Updated on : Sunday, 02 May 2020.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_BRANCH_TYPE
{
	Headquarters = 0,
	Branch = 1,
	MRO = 2,
	ROS = 3
}

/* ENUM_BRANCH_TYPE - END */

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_RELIGION_FOLLOWER - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_FOLLOWER
{
	Moslem = 0,
	Catholic = 1,
	Christian = 2,
	Hindu = 3,
	Buddhist = 4,
	Confucianist = 5,
}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_IDENTIFICATION_TYPE - START
		Description : Enum for the identification type this will trigger how you treat the identification number.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_IDENTIFICATION_TYPE
{
	IdentityCard = 0,
	DrivingLicense = 1,
	TemporaryStayCard = 2,
	PermanentStayCard = 3,
	Passport = 5
}

/* ENUM_IDENTIFICATION_TYPE - END */

/*
		ENUM_EDUCATION_TYPE - START
		Description : Enum for education type, these are the main education in Indonesia.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EDUCATION_TYPE
{
	ElementarySchool = 0,
	JuniorHighSchool = 1,
	SeniorHighSchool = 2,
	AssociateDegree = 3,
	BachelorDegree = 4,
	MasterDegree = 5,
	DoctoralDegree = 6
}

/* ENUM_EDUCATION_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee type, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Monday, 11 January 2021.          Updated on : Monday, 11 January 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_TYPE
{
	Volunteer = 0,
	Internship = 1,
	Freelance = 2,
	PartTime = 3,
	OutSource = 4,
	Contract = 5,
	FullTime = 6
}

/* ENUM_EMPLOYMENT_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee Grade, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Thursday, 18 February 2021.          Updated on : Thursday, 18 February 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_GRADE
{
	Zero = 0,
	One = 1,
	Two = 2,
	Three = 3,
	Four = 4,
	Five = 5,
	Six = 6,
	Seven = 7,
	Eight = 8,
	Nine = 9,
	Ten = 10,
	Eleven = 11,
	Twelve = 12,
	Thirteen = 13,
	Fourteen = 14,
	Fifteen = 15
}

/* ENUM_EMPLOYMENT_TYPE - END */


/*
		ENUM_SERVER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SERVER_TYPE
{
	user,
	product,
	Policy
}

/* ENUM_SERVER_TYPE - END */

/*
		ENUM_REGISTER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_REGISTER_TYPE
{
	KenalanMobilePhone = 0,
	Facebook = 1,
	Google = 2,
	Linkedin = 3,
	Apple = 4,
	Twitter = 5,
	Line = 6,
	KakaoTalk = 7,
	KenalanEmail = 8,
}

/* ENUM_REGISTER_TYPE - END */

/*
		ENUM_SALUTATION_TYPE - START
		Description : Set of constants for salutation type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SALUTATION_TYPE
{
	Miss,
	Mr,
	Mrs
}

/* ENUM_SALUTATION_TYPE - END */

/*
		ENUM_PHOTO_TYPE - START
		Description : Set of constants for photo type accross product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PHOTO_TYPE
{
	BuildingFront,
	BuildingLeft,
	BuildingRight,
	BuildingBack,
	BuildingInterior,
	VehicleFront,
	VehicleLeft,
	VehicleRight,
	VehicleBack,
	VehicleInterior,
	STNK,
	KTP,
	ClientApproval,
	PaymentReceipt,
	Visit,
	PoliceReportLetter,
	KirReport,
	DrivingLicense,
	ClaimThirdPartyLetter,
	IdentityThirdParty,
	PeaceAgreementLetter,
	FrontBumperLeft,
	FrontBumperRight,
	FrontGrillLeft,
	FrontGrillRight,
	FrontPanel,
	HeadLampLeft,
	HeadLampRight,
	FrontCombLampLeft,
	FrontCombLampRight,
	PanelBullheadLamp,
	FrontBumperSignLeft,
	FrontBumperSignRight,
	FrontFenderLeft,
	FrontFenderRight,
	FrontDoorLeft,
	FrontDoorRight,
	RearDoorLeft,
	RearDoorRight,
	LinearFrontFenderLeft,
	LinearFrontFenderRight,
	LinearReadFenderLeft,
	LinearReadFenderRight,
	BaggageOrTrunkCover,
	RadiatorPanelUpper,
	RadiatorPanelLower,
	AprontPanelAssyLeft,
	AprontPanelAssyRight,
	OilPan,
	ReservoirTank,
	Accu,
	FrontGlassPillarLeft,
	FrontGlassPillarRight,
	FrontGlass,
	FrontGlassRubber,
	FrontGlassWindowLeft,
	FrontGlassWindowRight,
	RearGlassWindowLeft,
	RearGlassWindowRight,
	ReadOrBackGlass,
	SpionOrOuterMirrorLeft,
	SpionOrOuterMirrorRight,
	StopLampLeft,
	StopLampRight,
	RearCombLampLeft,
	RearCombLampRight,
	RearBumper,
	QuarterPanelLeft,
	QuarterPanelRight,
	Condensor,
	Compressor,
	Pipes,
	RefillFreon,
	Dryer,
	EngineMounting,
	Radiator,
	VelRearLeft,
	VelRearRight,
	VelFrontLeft,
	VelFrontRight,
	LegalResponsibilityDocument,
	ClaimLetter,
	IncidentChronologyDocumentPageOne,
	IncidentChronologyDocumentPageTwo,
	SurveyVideo,
	Other,
	Form10B,
	VehicleDataExcelTemplate,
	ApealLetter,
	SupportingDocument,
	EngineHood,
	TriplangRight,
	TriplangLeft,
	Roof,
	SideRoofRight,
	SideRoofLeft,
	Crossmember,
	SpoilerFrontBumper,
	ObjectOfLoss,
	SupportingDocument2,
	SupportingDocument3,
	SupportingDocument4,
	SupportingDocument5,
	SpoilerRearBumper,
	SpoilerTriplang,
	RightCoverTriplang,
	LeftCoverTriplang,
	FrontGrill,
	RightDoorHandle1,
	LeftDoorHandle1,
	RightDoorHandle2,
	LeftDoorHandle2,
	RearDoorTrimRightside,
	RearDoorTrimLeftSide,
	FrontDoorTrimRightside,
	FrontDoorTrimLeftSide,
	FrontMoldingBumperRightSide,
	RearMoldingBumperRightSide,
	FrontMoldingBumperLeftSide,
	RearMoldingBumperLeftSide,
	FrontMoldingFenderRightSide,
	FrontMoldingFenderLeftSide,
	MoldingDoorTrunk,
	MoldingQPanelRightSide,
	MoldingQPanelLeftSide,
	SpoilerTrunk,
	PilarARightSide,
	PilarALeftSide,
	PilarBRightSide,
	PilarBLeftSide,
	PilarCRightSide,
	PilarCLeftSide,
	TitikKerusakan1,
	TitikKerusakan2,
	TitikKerusakan3,
	TitikKerusakan4,
	TitikKerusakan5,
	OutlookArticleAttachment = 154
}

/* ENUM_PHOTO_TYPE - END */

/*
		ENUM_ENVIRONMENT_TYPE - START
		Description : Set of constants for environment type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_ENVIRONMENT_TYPE
{
	Local = 0,
	Development = 1,
	Staging = 2,
	Production = 3,
}

/* ENUM_ENVIRONMENT_TYPE - END */

/*
		ENUM_PROJECT_TYPE - START
		Description : Set of constants for project type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PROJECT_TYPE
{
	Anjani,
	Bromo,
	Kartini,
	Dharmawangsa,
	Natuna,
	Staging,
	Production
}

/* ENUM_PROJECT_TYPE - END */

/*
		ENUM_VIRTUALACCOUNT_TYPE - START
		Description : Set of constants for virtual account type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VIRTUALACCOUNT_TYPE
{
	Briva = 0
}

/* ENUM_VIRTUALACCOUNT_TYPE - END */

/*
		ENUM_PDFPREVIEW_TYPE - START
		Description : Set of constants for pdf preview type, to define what model to open from product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PDFPREVIEW_TYPE
{
	ProductAsriCalculatePremium = 0,
	ProductAsriSPPA = 1,
	ProductOtoCalculatePremium = 2,
	ProductOtoSPPA = 3,
	ProductPersonalAccidentCalculatePremium = 4,
	ProductPersonalAccidentSPPA = 5,
	PolicyOutstanding = 6,
	PolicyRenewal = 7
}

/* ENUM_PDFPREVIEW_TYPE - END */

/*
		ENUM_LETTER_TYPE - START
		Description : Set of constants for letter type, to define what serial number need to be generated.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          	Updated on : Friday, 16 April 2021.
		Created by : Andri Septiawan.						Updated by : Billy Johannes.
		Version : 1.1:0.
	*/

export enum ENUM_LETTER_TYPE
	{
		DeclarationPayment = 1,
		DeclarationReimburse = 2,
		DeclarationRealizationDownPayment = 3,
		DeclarationRealizationDebt = 4,
		SubmissionDownPayment = 5,
		SubmissionDebt = 6,
		OfficialMemo = 7,
		LetterOut = 8,
		LetterIn = 9,
		Outstanding = 10,
		Renewal = 11
	}

/* ENUM_LETTER_TYPE - END */

/*
	ENUM_PRODUCT_TYPE - START
	Description : Set of constants for product type, to use in search by attributes.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.			Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_PRODUCT_TYPE
{
	Conventional = 0,
	Special = 1,
	Syariah = 2,
	Customize = 3
}

/* ENUM_PRODUCT_TYPE - END */

/*
    ENUM_PLATFORM_TYPE - START
    Description : Set of constants for platform type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_PLATFORM_TYPE
{
	Windows = 0,
	Mac = 1,
	Linux = 2,
	Android = 3,
	IOS = 4
}

/* ENUM_PLATFORM_TYPE - END */

/*
ENUM_DEVICE_TYPE - START
Description : Set of constants for device type for analytics and issue tracking.
Author : Andri Septiawan.
Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
Version : 1.0:1.
*/

export enum ENUM_DEVICE_TYPE
{
	Desktop = 0,
	Website = 1,
	Handphone = 2,
	Tablet = 3
}

/* ENUM_PLATFORM_TYPE - END */

/*
ENUM_SORT_TYPE - START
Description : Set of constants for device type for analytics and issue tracking.
Author : Andri Septiawan.
Created on : Thursday, 18 February 2021.   	Updated on : Thursday, 18 February 2021.
Created by : Andri Septiawan.          		Updated by : Andri Septiawan.
Version : 1.0:1.
*/

export enum ENUM_SORT_TYPE
{
	Ascending = 0,
	Descending = 1,
}

/* ENUM_PLATFORM_TYPE - END */

/*
	ENUM_SEVERITY_TYPE - START
	Description : Set of constants for severity status for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_SEVERITY_TYPE
{
	Low = 0,
	Medium = 1,
	High = 2,
	Urgent = 3
}

/* ENUM_SEVERITY_TYPE - END */

/*
	ENUM_TICKETCHAT_TYPE - START
	Description : Set of constants for ticket chat type for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_TICKETCHAT_TYPE
{
	ChatPrivacy = -1,
	InternalBRINS = 0,
	General = 1
}

/* ENUM_TICKETCHAT_TYPE - END */

/*
	ENUM_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Billy Johannes.
	Created on : Monday, 24 May 2021.          	Updated on : Monday, 24 May 2021.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.1:0.
*/

export enum ENUM_TOPIC_TYPE
{
	Information = 0,
	Claim = 1,
	Complaint = 2,
	BRI = 3,
	Others = 4
}

/* ENUM_TOPIC_TYPE - END */



/*
		ENUM_PAYMENT_TYPE - START
		Description : For payment type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENT_TYPE
	{
		Tunai = 0,
		Transfer = 1
	}

/* ENUM_PAYMENT_TYPE - END */


/*
		ENUM_PAYMENTPRIORITY_TYPE - START
		Description : For payment priority type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENTPRIORITY_TYPE
	{
		Normal = 0,
		Important = 1,
		VeryImportant = 2
	}

/* ENUM_PAYMENTPRIORITY_TYPE - END */

/*
	ENUM_CLAIM_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Andri Septiawan.
	Created on : Wednesday, 24 June 2021.     	Updated on : Wednesday, 24 June 2021.
	Created by : Andri Septiawan.				Updated by : Andri Septiawan.
	Version : 1.0:0.
*/

export enum ENUM_CLAIM_STATE
{
	PendingForSurvey = 0,
	Adjustment = 1,
	Approved = 2,
	Rejected = 3,
	Paid = 4
}

/* ENUM_TOPIC_TYPE - END */

/*
	ENUM_PROFILE_TYPE - START
	Description : Set of constants for Executive Profile type for Renewal.
	Author : Andri Septiawan.
	Created on : Wednesday, 24 June 2021.     	Updated on : Wednesday, 24 June 2021.
	Created by : Andri Septiawan.				Updated by : Andri Septiawan.
	Version : 1.0:0.
*/

export enum ENUM_PROFILE_TYPE
{
	Captive = 0,
	NonCaptive = 1,
}

/* ENUM_PROFILE_TYPE - END */

//#endregion


/*
	ENUM_EXECUTIVE_POLICY - START
	Description : Set of constants for Executive Policy topic type for Renewal.
	Author : Andri Septiawan.
	Created on : Wednesday, 24 June 2021.     	Updated on : Wednesday, 24 June 2021.
	Created by : Andri Septiawan.				Updated by : Andri Septiawan.
	Version : 1.0:0.
*/

export enum ENUM_EXECUTIVE_POLICY
{
	Vvip = 0,
	NonVvip = 1
}

/* ENUM_EXECUTIVE_POLICY - END */


/* ENUM_ACCEPTANCE_STATUS - END */

/*
	ENUM_ACCEPTANCE_STATUS - START
	Description : Set of constants for Acceptance type.
	Author : Andri Septiawan.
	Created on : Tuesday, 16 November 2021.          Updated on : Tuesday, 16 November 2021.
	Created by : Andri Septiawan.						Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_ACCEPTANCE_STATUS
{
	Waiting = 0,
	Inforced = 1,
	Closed = 2,
	Temporary = 3
}

/* ENUM_ACCEPTANCE_STATUS - END */

//#endregion


//#region STATUS

/*
		ENUM_LETTER_STATUS - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Friday, 19 March 2021.        		Updated on : Friday, 16 April 2021..
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.1:1.
	*/

export enum ENUM_LETTER_STATUS
	{
		OnProgress = 0,
		PendingForCheck = 1,
		PendingForSign = 2,
		Rejected = 3,
		Signed = 4,
		Printed = 5,
		Sent = 6
	}

/* ENUM_LETTER_STATUS - END */

/*
	ENUM_MARITAL_STATUS - START
	Description : Set of constants for marital status.
	Author : Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_MARITAL_STATUS
{
	Single = 0,
	Married = 1,
	Divorce = 2,
	Widow = 3,
	Placeholder = 10
}

/* ENUM_MARITAL_STATUS - END */

/*
		ENUM_HTTP_STATUS - START
		Description : List of HTTP response code this for the history and response to our service.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.				    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_HTTP_STATUS
{
	/* INFORMATIONAL - START*/

	Continue = 100,
	SwitchingProtocol = 101,
	Processing = 102,

	/* INFORMATIONAL - END */

	/* SUCCESS - START */

	Ok = 200,
	Created = 201,
	Accepted = 202,
	NonAuthorativeInformation = 203,
	NoContent = 204,
	ResetContent = 205,
	PartialContent = 206,
	MultiStatus = 207,
	AlreadyReported = 208,
	IMUsed = 226,

	/* SUCCESS - END */

	/* REDIRECTION - START */

	MultipleChoices = 300,
	MovedPermanently = 301,
	Found = 302,
	SeeOther = 303,
	NotModified = 304,
	UseProxy = 305,
	Unused = 306,
	TemporaryRedirect = 307,
	PermanentRedirection = 308,

	/* REDIRECTION - END */

	/* CLIENT ERROR - START */

	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestURITooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	ImATeapot = 418,
	EnhanceYourCalm = 420,
	UnprocessableEntity = 422,
	Locked = 423,
	FailedDependency = 424,
	ReservedForWebDAV = 425,
	UpgradeRequired = 426,
	PreconditionRequires = 428,
	TooManyRequest = 429,
	RequestHeaderFieldsTooLarge = 431,
	NoResponse = 444,
	RetryWith = 449,
	BlockedByWindowsParentalControls = 450,
	UnavailableForLegalReasons = 451,
	ClientClosedRequest = 499,
	BlockedByCORS = 0,

	/* CLIENT ERROR - END */

	/* SERVER ERROR - START */

	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HTTPVersionNotSupported = 501,
	VariantAlsoNegotiates = 506,
	InsufficientStorage = 507,
	LoopDetected = 508,
	BandwithLimitExceeded = 509,
	NotExtended = 510,
	NetworkAuthenticationRequired = 511,
	NetworkReadTimeoutError = 598,
	NetworkConnectTimeoutError = 599

	/* SERVER ERROR - END */
}

/* ENUM_HTTP_STATUS - END */

/*
		ENUM_SURVEY_STATUS - START
		Description : Set of constants for survey status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SURVEY_STATUS
{
	Pending = 0,
	Surveyed = 1,
	Canceled = 2,
	Verify = 3,
	Approved = 4,
	Rejected = 5,
	Resurvey = 6
}

/* ENUM_SURVEY_STATUS - END */

/*
		ENUM_UNDERWRITING_STATUS - START
		Description : Set of constants for underwriting status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_UNDERWRITING_STATUS
{
	Pending = 0,
	Approved = 1,
	Rejected = 2,
}

/* ENUM_UNDERWRITING_STATUS - END */

/*
		ENUM_SPPA_STATUS - START
		Description : Set of constants for sppa status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SPPA_STATUS
{
	OnProgress = 0,
	ReadyForSave = 1,
	Saved = 2,
	Approved = 3,
	PendingForSurvey = 4,
	RejectedSurvey = 5,
	PendingForUnderwriting = 6,
	RejectedUnderwritting = 7,
	PendingForPayment = 8,
	Paid = 9,
	Completed = 10
}

/* ENUM_SPPA_STATUS - END */

/*
	ENUM_POLICY_STATUS - START
	Description : Set of constants for policy status.
	Author : Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_POLICY_STATUS
{
	Active = 1,
	Outstanding = 2,
	Inactive = 3,
	Expired = 4,
	Terminated = 5,
	Canceled = 6,
	Pending = 7
}

/* POLICY_STATUS - END */

/*
	ENUM_TICKET_STATUS - START
	Description : Set of constants for ticket status for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

export enum ENUM_TICKET_STATUS
{
	Pending = 0,
	InProgress = 1,
	ClosedWithConfirmation = 2,
	ClosedWithoutConfirmation = 3,
	NotClosedWithConfirmation = 4,
	NotClosedWithoutConfirmation = 5,
	Canceled = 6
}

/* ENUM_TICKET_STATUS - END */

//#endregion


//#region MISELANIOUS

/*
	ENUM_FONT_FAMILY - START
	Description : This will be tight to user model, to set up the font family preference.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_FONT_FAMILY
{
	Primary = 0,
	Second = 1
}

/* ENUM_FONT_FAMILY - END */

/*
		ENUM_FONT_SIZE - START
		Description : This will be tight to user model, to set up the font size preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_SIZE
{
	Small = 0,
	Medium = 1,
	Large = 2
}

/* ENUM_FONT_SIZE - END */

/*
		ENUM_THEME_COLOUR - START
		Description : This will be tight to user model, to set up the theme color preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_THEME_COLOUR
{
	Fioralba = 0,
	Aurora = 1
}

/* ENUM_THEME_COLOUR - END */

/*
	ENUM_DATE_MONTH - START
	Description : This will be used for spinner date.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_DATE_MONTH
{
	January = 1,
	February = 2,
	March = 3,
	April = 4,
	May = 5,
	June = 6,
	July = 7,
	August = 8,
	September = 9,
	October = 10,
	November = 11,
	December = 12
}

/* ENUM_DATE_MONTH - END */

/*
	ENUM_USER_ROLE - START
	Description : This role user in anjani website.
	Author: Andri Septiawan.
	Created on : Wednesday, 13 January 2020.        Updated on : Wednesday, 13 January 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_USER_ROLE
{
	RelationshipManager = 0,
	Business = 1
}

/* ENUM_USER_ROLE - END */

/*
	ENUM_LETTER_ROLE - START
	Description : This letter role used accross website to determine letter process.
	Author: Ibrahim Aziz.
	Created on : Monday, 19 April 2021.        		Updated on : Monday, 19 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_LETTER_ROLE
{
	Maker = 0,
	Checker = 1,
	Signer = 2
}

/* ENUM_LETTER_ROLE - END */

/*
	ENUM_DIVISION_ID - START
	Description : This division id used accross website to determine division validation process.
	Author: Billy Johannes.
	Created on : Friday, 28 May 2021.        		Updated on : Friday, 28 May 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_DIVISION_ID
{
	NoDivision = 0,
	OperationAndSupport = 15,
}

/* ENUM_DIVISION_ID - END */

//#endregion


/* ENUM_LETTER_ROLE - END */

/*
	ENUM_DIVISION_ID - START
	Description : This division id used accross website to determine division validation process.
	Author: Billy Johannes.
	Created on : Friday, 28 May 2021.        		Updated on : Friday, 28 May 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_SEGMENT_REPORT
{
	Digital = 0,
	Korporasi = 1,
	Mikro = 2,
	Retail = 3,
	Syariah = 4,
}

/* ENUM_DIVISION_ID - END */

/*
		ENUM_CLAIMPROPOSAL_STATUS - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Monday, 20 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:2.
	*/

export enum ENUM_CLAIMPROPOSAL_STATUS
{
	Verified = 0,
	NotVerified = 1,
	RejectedByBranch = 2,
	PendingForHeadOffice = 3,
	PendingForCheckerHeadOffice = 4,
	PendingForSignerHeadOffice = 5,
	RejectedByHeadOffice = 6,
	PendingForSurvey = 7,
	CompletedWithoutSurvey = 8,
	CompletedWithSurvey = 9,
	Approved = 10,
	Rejected = 11,
	PendingForCorrection = 12
}

/* ENUM_CLAIMPROPOSAL_STATUS - END */

/*
		ENUM_CLAIMITEM_TYPE - START
		Description : Set of constants for item survey.
		Author : Eka Saputra.
		Created on : Saturday, 3 October 2021.			Updated on : Saturday, 3 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIMITEM_TYPE
{
	Repair = 0,
	Replace = 1,
	Salvage = 2,
}

/* ENUM_CLAIMITEM_TYPE - END */

//#endregion

/*
	ENUM_CLAIMPROPOSAL_SURVEYADDRESS - START
	Description : Set of constants for insured location.
	Author : Eka Saputra.
	Created on : Friday, 10 September 2021.			Updated on : Friday, 10 September 2021.
	Created by : Eka Saputra.						Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_CLAIMPROPOSAL_SURVEYADDRESS
{
SameAsPolicyHolder = 0,
Branch = 1,
BranchCoverage = 2,
UserLocation = 3,
InputText = 4,
}

/* ENUM_CLAIMPROPOSAL_SURVEYADDRESS - END */

/*
	ENUM_THIRDPARTYLIABILATION_TYPE - START
	Description : Set of constants for TJH.
	Author : Eka Saputra.
	Created on : Friday, 24 September 2021.			Updated on : Friday, 21 September 2021.
	Created by : Eka Saputra.						Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_THIRDPARTYLIABILATION_TYPE
{
Car = 0,
TruckOrVan = 1,
MotorcycleOrOtherVehicles = 2,
Fatalities = 3,
Other = 4,
}

/* ENUM_THIRDPARTYLIABILATION_TYPE - END */

/*
	ENUM_INCIDENT_TYPE - START
	Description : Set of constants for incident type claim.
	Author : Eka Saputra.
	Created on : Tuesday, 15 Juli 2021.          	Updated on : Tuesday, 15 Juli 2021.
	Created by : Eka Saputra.						Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_INCIDENT_TYPE
{
	Crash = 0,
	ReverseOrFell = 1,
	CriminalOrCrime = 2,
	Fire = 3,
	FloodOrNaturalDisaster = 4,
	Riot = 5,
	Terrorism = 6,
	HitByATreeOrBuilding = 7,
	Lost = 8
}

/* ENUM_TICKETCHAT_TYPE - END */

/*
		ENUM_REVIEW_STATE - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Friday, 20 August 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_REVIEW_STATE
{
	Pending = 0,
	Verified = 1,
	Approved = 2,
	Rejected = 3,
}

/* ENUM_REVIEW_STATE - END */

/* ENUM_WORKSHOP_TYPE - END */

/*
		ENUM_WORKSHOP_TYPE - START
		Description : Set of constants for item type workshop.
		Author : Eka Saputra.
		Created on : Monday, 11 October 2021.			Updated on : Monday, 11 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_WORKSHOP_TYPE
{
	Authorized = 0,
	NonAuthorized = 1,
}

/* ENUM_WORKSHOP_TYPE - END */

/*
		ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE - START
		Description : Set of constants for approval condition type claim proposal.
		Author : Eka Saputra.
		Created on : Thursday, 14 October 2021.			Updated on : Thursday, 14 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE
{
	MotorVehicle,
	Personal,
	Other
}

/* ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE - END */

/*
		ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE - START
		Description : Set of constants for approval condition type claim proposal.
		Author : Eka Saputra.
		Created on : Thursday, 14 October 2021.			Updated on : Thursday, 14 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE
{
	Approve = 0,
	Reject = 1,
}

/* ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE - END */

/*
		ENUM_BRIPOLICYENDORSEMENT_STATUS - START
		Description : Set of constants for status condition type bri policy endorsement.
		Author : Eka Saputra.
		Created on : Thursday, 4 November 2021.			Updated on : Thursday, 4 November 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRIPOLICYENDORSEMENT_STATUS
{
	Send = 0,
	Rejected = 1,
	Endorsed = 2
}

/* ENUM_BRIPOLICYENDORSEMENT_STATUS - END */

/*
		ENUM_CLAIM_COVERAGE - START
		Description : Set of constants for status condition type bri policy endorsement.
		Author : Eka Saputra.
		Created on : Monday, 22 November 2021.			Updated on : Monday, 22 November 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIM_COVERAGE
{
	Rcover = 0,
	Icover = 1,
	Acccla = 2,
	Accded = 3,
}

/* ENUM__COVERAGE - END */

/*
		ENUM_ABOUT_BRINS - START
		Description : Set of constants for type about of brins .
		Author : Eka Saputra.
		Created on : Thursday, 27 January 2022.			Updated on : Thursday, 27 January 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_ABOUT_BRINS
{
	CorporateValues = 0,
	CompanyProfile = 1,
	OrganizationAndStructure = 2,
	Award = 3,
	PartnerAndClient = 4,
	ContactAndLocation = 5,
}

/* ENUM_ORGANIZATIONANDSTRUCTURE_BRINS - END */

/*
		ENUM_ORGANIZATIONANDSTRUCTURE_BRINS - START
		Description : Set of constants for organization and structure of brins .
		Author : Eka Saputra.
		Created on : 31 January 2022.		Updated on : Monday, 31 January 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_ORGANIZATIONANDSTRUCTURE_BRINS
{
	Komisaris,
	Direksi,
	DewanPengawasSyariah,
	KepalaDivisi,
}

/* ENUM_ORGANIZATIONANDSTRUCTURE_BRINS - END */

/*
		ENUM_ECONOMICVALUE_TYPE - START
		Description : Set of constants for type of economic value .
		Author : Eka Saputra.
		Created on : Wednesday, 16 November 2022.		Updated on : Wednesday, 16 November 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_ECONOMICVALUE_TYPE
{
	Konvensional,
	Syariah,
	Konsolidasi
}

/* ENUM_SOCIALVALUE_GCG - END */

/*
		ENUM_SOCIALVALUE_GCG - START
		Description : Set of constants for type of social value .
		Author : Eka Saputra.
		Created on : Tuesday, 08 March 2022.			Updated on : Tuesday, 08 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SOCIALVALUE_GCG
{
	Pedoman,
	Gratifikasi,
	APU,
	Kepentingan
}

/* ENUM_SOCIALVALUE_GCG - END */

/*
		ENUM_SOCIALVALUE_GCG - START
		Description : Set of constants for type of social value .
		Author : Eka Saputra.
		Created on : Tuesday, 08 March 2022.			Updated on : Tuesday, 08 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SOCIALVALUE_RISKMANAGEMENT
{
	Pedoman,
	AntiFraud,
	BCM,
	Covid19
}

/* ENUM_SOCIALVALUE_GCG - END */

/*
		ENUM_SOCIALVALUE_GCG - START
		Description : Set of constants for type of social value .
		Author : Eka Saputra.
		Created on : Tuesday, 08 March 2022.			Updated on : Tuesday, 08 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SOCIALVALUE_SUBMAIN
{
	GoodCorporateGovernance,
	RiskManagement,
	Sustainability,
	WhistleblowingSystem
}

/* ENUM_PARTNERANDCLIENT_BRINS - END */

/*
		ENUM_PARTNERANDCLIENT_BRINS - START
		Description : Set of constants for type of social value .
		Author : Eka Saputra.
		Created on : Tuesday, 08 March 2022.			Updated on : Tuesday, 08 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_PARTNERANDCLIENT_BRINS
{
	Digital,
	RetailAndMikro,
	BUMN,
	ReinsuranceBroker,
	InsuranceCompany,
}

/* ENUM_NAVIGATIONMENU_BRINS - END */

/*
		ENUM_NAVIGATIONMENU_BRINS - START
		Description : Set of constants for type of social value .
		Author : Eka Saputra.
		Created on : Tuesday, 08 March 2022.			Updated on : Tuesday, 08 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_NAVIGATIONMENU_BRINS
{
	AboutUs,
	SocialValue,
	EconomicValue,
	BRINSUpdate,
	CustomerCare,
	JoinUs,
	AMS,
	Product,
	Insurtech,
	Notification,
	Home
}

/* ENUM_NAVIGATIONMENU_BRINS - END */

/*
		ENUM_PILLARBUSINESS_TYPE - START
		Description : Set of constants for type of pillar business .
		Author : Eka Saputra.
		Created on : Friday, 18 March 2022.				Updated on : Friday, 18 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_PILLARBUSSINES_TYPE
{
	Corporate,
	Ritel,
	Mikro,
	Syariah,
}

/* ENUM_PILLARBUSSINES_TYPE - END */

/*
		ENUM_SUBMISSIONCLAIMNONMV_TYPE - START
		Description : Set of constants for type of submission claim non mv .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SUBMISSIONCLAIMNONMV_TYPE
{
	Reporter,
	Loss,
	Supporting,
}

/* ENUM_SUBMISSIONCLAIMNONMV_TYPE - END */

/*
		ENUM_SUBMISSIONCLAIMMV_TYPE - START
		Description : Set of constants for type of submission claim mv .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SUBMISSIONCLAIMMV_TYPE
{
	Reporter,
	Accident,
	Survey,
	Supporting,
}

/* ENUM_SUBMISSIONCLAIMNONMV_TYPE - END */

/*
		ENUM_BRINSUPDATE_TYPE - START
		Description : Set of constants for type of BRINS Update .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRINSUPDATE_TYPE
{
	Award = 0,
	Community = 1,
	Discount = 2,
	FinancialLiteracy = 3,
	NewsFeed = 4,
	Testimony = 5,
}

/* ENUM_SUBMISSIONCLAIMNONMV_TYPE - END */

/*
		ENUM_AWARD_TYPE - START
		Description : Set of constants for type of Award .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_AWARD_TYPE
{
	BestAward = 0,
	Award = 1,
}

/* ENUM_SUBMISSIONCLAIMNONMV_TYPE - END */

/*
		ENUM_BRINSUPDATE_PRIORITY - START
		Description : Set of constants for type of priority BRINS Update .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRINSUPDATE_PRIORITY
{
    Normal = 0,
    Highlight = 1,
}

/* ENUM_BRINSUPDATE_PRIORITY - END */

/*
		ENUM_NEWSFEED_PRIORITY - START
		Description : Set of constants for type of priority news feeds .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_NEWSFEED_PRIORITY
{
    NotShow = 0,
	Show = 1,
	Priority = 2,
}

/* ENUM_NEWSFEED_PRIORITY - END */

/*
		ENUM_AWARD_PRIORITY - START
		Description : Set of constants for type of priority award .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_AWARD_PRIORITY
{
    Normal = 0,
    Best = 1,
	VeryBest = 2
}

/* ENUM_FAQ_TYPE - END */

/*
		ENUM_FAQ_TYPE - START
		Description : Set of constants for type of faq .
		Author : Eka Saputra.
		Created on : Tuesday, 05 April 2022.				Updated on : Tuesday, 05 April 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_FAQ_TYPE
{
    AboutBRINS = 1,
    CustomerCare = 2,
    Product = 3,
    BRINSMobile = 4,
    BRINSWebsite = 5,
}

/* ENUM_FAQ_TYPE - END */

/*
    ENUM_PROJECT_CODE - START
    Description : Set of constants for status type adjuster.
    Author : Bondhan Jantri Ramadhan.
    Created on : Sunday, 17 July 2022.      	Updated on : Sunday, 17 July 2022.
    Created by : Eka Saputra					Updated by : Eka Saputra.
    Version : 1.0:0
*/

export enum ENUM_PROJECT_CODE
{
	Anjani = 1,
	Bromo = 2,
	Citrasena = 3,
	Dharmawangsa = 4,
	Eboni = 5,
	Foja = 6,
	Gili = 7,
	Halimun = 8,
	Ismaya = 9,
	Joho = 10,
	Kartini = 11,
	Legian = 12,
	Maninjau = 13,
	Natuna = 14,
	Opak = 15,
	Patma = 16,
	Quartz = 17,
	Rinjani = 18,
	Sentani = 19,
	Trijata = 20,
	Ungaran = 21,
	VanDaalen = 22,
	Widura = 23,
	Xanthite = 24,
	Yaro = 25,
	Zircon = 26,
	Arjuna = 27,
	Bima = 28,
	Ciremai = 29,
	Diponegoro = 30,
	Ekalawya = 31,
	Fatmawati = 32,
	Gamalama = 33,
	Halmahera = 34,
	Intata = 35,
	Jayawijaya = 36,
	Karimun = 37,
	Laksmana = 38,
}

/* ENUM_PROJECT_CODE - END */

/*
		ENUM_CLAIM_CHANNEL - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Monday, 20 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:2.
*/

export enum ENUM_CLAIM_CHANNEL
{
	Claim = 0,
	OperationAndSupport = 1,
	CustomerWebsite = 2,
	RelationshipManager = 3,
	BranchAdmin = 4,
	CustomerMobile = 5,
}

/* ENUM_CLAIM_CHANNEL - END */


/*
	ENUM_PROMOCODE_PRIORITY - START
	Description : Set of constants for priority.
	Author : Dimas Dandy Aryarajendra Suprapto.
	Created on : Wednesday, 15 February 2023.          				Updated on : Wednesday, 15 February 2023.
	Created by : Dimas Dandy Aryarajendra Suprapto.					Updated by : Dimas Dandy Aryarajendra Suprapto.
	Version : 1.0:1.
*/

export enum ENUM_PROMOCODE_PRIORITY
{
	NotShow = 0,
	Show = 1,
	Priority = 2,
}

/* ENUM_PROMOCODE_PRIORITY - END */


/*
	ENUM_CLAIMPROPOSAL_TRACKING_PHASE - START
	Description : Set of constants for tracking pahse on tracking claim.
	Author : Anjar Kusworo.
	Created on : Monday, 26 February 2023.          	Updated on : -
	Created by : Andryana Baros.						Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_CLAIMPROPOSAL_TRACKING_PHASE
{
	Reporting = 0,
	Mantle = 1,
	Processing = 2,
	Finishing = 3,
	ClaimAdministration = 4,
	PaymentAKU = 5,
	PaymentWorkshop = 6,
	Completed = 7
}

/* ENUM_CLAIMPROPOSAL_TRACKING_PHASE - END */


/*
	ENUM_QUESTION_FOR - START
	Description : Set of constants for enum question satisfaction survey.
	Author : Anjar Kusworo.
	Created on : Monday, 04 April 2023.          	Updated on : -
	Created by : Andryana Baros.						Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_QUESTION_FOR
{
	BRINS = 0,
	Workshop = 1,
	FeedbackBRINS = 2,
	FeedbackWorkshop = 3,
	Recommendation = 4,
	RecommendationItems = 5
}

/* ENUM_CLAIMPROPOSAL_TRACKING_PHASE - END */


/*
	ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - START
	Description : This is used for satisfaction ticket
	Author : Yozi Maulana
	Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
	Created by : Eka Saputra								Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE
{
	VeryDissatisfied = 0,
	NotSatisfied= 1,
	Satisfied= 2,
	VerySatisfied= 3,
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKARTICLE_STATUS - START
	Description : This is used for outlook article status
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKARTICLE_STATUS
{
	New = 0,
    Accepted = 1,
    Archived = 2
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOK_STATUS - START
	Description : This is used for outlook status
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOK_STATUS
{
	Published = 0,
    Archived = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKARTICLE_AUTHORWORKINGUNITTYPE - START
	Description : This is used for outlook article authord working unit type
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKARTICLE_AUTHORWORKINGUNITTYPE
{
	Headquarters = 0,
    Branch = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKGALLERY_TYPE - START
	Description : This is used for outlook gallery type
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKGALLERY_TYPE
{
	Cover = 0,
    Attachment = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_PRODUCT_OWNERSHIPINFORMATION - START
	Description : Set of constants for ownership of business place.
	Author : Eka Saputra.
	Created on : Friday, 27 May 2022.					Updated on : Friday, 27 May 2022.
	Created by : Eka Saputra.							Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_PRODUCT_OWNERSHIPINFORMATION
{
	Owner = 0,
	Tenant = 1
}

/* ENUM_PRODUCT_LOCATIONINFORMATION - END */


/*
		ENUM_PRODUCT_LOCATIONINFORMATION - START
		Description : Set of constants for location of business place.
		Author : Eka Saputra.
		Created on : Friday, 27 May 2022.					Updated on : Friday, 27 May 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_PRODUCT_LOCATIONINFORMATION
{
	MoreOneHundredMetersFromMarket = 0,
	LessOneHundredMetersFromMarket = 1
}

/* ENUM_PRODUCT_OWNERSHIPINFORMATION - END */


/*
		ENUM_CITIZENSHIP_STATUS - START
		Description : Set of constants for citizenship status.
		Author : Dimas Dandy.
		Created on : Monday, 12 August 2024.					Updated on : .
		Created by : Dimas Dandy.								Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_CITIZENSHIP_STATUS
{
	Indonesian = 0,
	Foreign = 1
}

/* ENUM_PRODUCT_OWNERSHIPINFORMATION - END */


//#endregion
