//#region IMPORT

import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_SOCIALVALUE_SUBMAIN, ENUM_SOCIALVALUE_GCG, ENUM_SOCIALVALUE_RISKMANAGEMENT } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { RegexFunction } from "src/app/functions/regex.function";
import { ClaimProposalGalleryModel } from "src/app/models/claimproposalgallery.model";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-socialvalue",
		templateUrl: "./socialvalue.component.html",
		styleUrls: ["./socialvalue.component.sass"],
	}
)

//#endregion


//#region CLASS

export class SocialValueComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _enumSocialValueGCG = ENUM_SOCIALVALUE_GCG;
	public _enumSocialValueRiskManagement = ENUM_SOCIALVALUE_RISKMANAGEMENT;
	public _enumSocialValueSubmain= ENUM_SOCIALVALUE_SUBMAIN;

	public _stringConstant = StringConstant;
	public _numberConstant = NumberConstant;

	public _arrayModelCSR: Array<ContentModel>;
	public _arrayModelContent5: Array<ContentModel>;
	public _arrayModelSocialValueType: Array<ContentModel>;
	public _arrayPageSizeSocialValueCSR: Array<number>;

	public _numberIndexBRINSUpdate: number;
	public _numberIndexSocialValueCSR: number;
	public _numberPageSizeSocialValueCSR: number;
	public _numberSocialValueGCGActive: number;
	public _numberSocialValueRiskActive: number;
	public _numberSocialValueSubmainActive: number;
	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _modelClaimProposalGallery: ClaimProposalGalleryModel;

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._numberIndexBRINSUpdate = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberIndexSocialValueCSR = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberSocialValueGCGActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberSocialValueRiskActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberSocialValueSubmainActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._modelClaimProposalGallery = new ClaimProposalGalleryModel();
		this._functionRegex = new RegexFunction();
		this._arrayModelCSR =
		[
			{
				ID: 0,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr1.png",
				Description: "BRINS Launching Product Greensurance Serta Program-Program CSR Untuk Membantu Masyarakat dan Lingkungan. (16/10/2021)",
			},
			{
				ID: 1,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr2.png",
				Description: "BRINS Salurkan Bantuan CSR Bagi Korban Erupsi Gunung Semeru. (05/12/2021)",
			},
			{
				ID: 2,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr3.png",
				Description: "BRINS Menggalakan CSR TJSL Penghijauan Pohon Koleksi Endemik Pegunungan di Taman Hutan Raya Bandung. (22/12/2021)",
			},
			{
				ID: 3,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr4.png",
				Description: "BRI Insurance Salurkan Bantuan CSR Kepada Korban Banjir Jayapura. (13/1/2022)",
			},
			{
				ID: 4,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr5.jpg",
				Description: "CSR ‘Protect Nature, Protect Future’ (Brins) tanam 1.000 bibit mangrove dan revitalisasi tempat usaha di wilayah Ekowisata Mangrove Pantai Indah Kapuk, Jakarta Utara. (23/07/2021)",
			},
			{
				ID: 5,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr6.jpg",
				Description: "CSR Renovasi Tempat Usaha Matas Culinary Corner di daerah Cilandak, Jakarta Selatan. (16/09/2022)",
			},
			{
				ID: 6,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/csr/csr7.jpg",
				Description: "CSR Literasi Asuransi, Sapa Komunitas bersama White Car Indonesia (WCI) dan Jawa Pos. (12/11/2022)",
			},
		];
		this._arrayModelContent5 =
		[
			{
				ID: 0,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/CEO TALK _Harmonizing Strategy with Culture Transformation_ Tahun 2021.jpg",
				Description: "CEO Talk \"Harmonizing Strategy with Culture Transformation\" Tahun 2021.",
			},
			{
				ID: 1,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Gala Dinner Acara Pisah Sambut Branch Manager BRINS di Hyatt Regency Yogyakarta.JPG",
				Description: "Gala Dinner Acara Pisah Sambut Branch Manager BRINS di Hyatt Regency Yogyakarta.",
			},
			{
				ID: 2,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Gowes Bersama dalam Rangka HUT ke-33 BRINS Tahun 2021.JPG",
				Description: "Gowes Bersama dalam Rangka HUT ke-33 BRINS Tahun 2021.",
			},
			{
				ID: 3,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Kegiatan Literasi Asuransi dengan Tema Jagain Pemuda Dalam Rangka Memperingati Hari Sumpah Pemuda Tahun 2021.jpg",
				Description: "Kegiatan Literasi Asuransi dengan Tema Jagain Pemuda Dalam Rangka Memperingati Hari Sumpah Pemuda Tahun 2021.",
			},
			{
				ID: 4,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Penandatanganan Perjanjian Kerjasama Antara BRINS dengan BUKALAPAK Tahun 2021.jpg",
				Description: "Penandatanganan Perjanjian Kerjasama Antara BRINS dengan BUKALAPAK Tahun 2021.",
			},
			{
				ID: 5,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Seminar Designing Strategic Execution in Building Resilient and Sustainable Insurance Tahun 2021.jpg",
				Description: "Seminar Designing Strategic Execution in Building Resilient and Sustainable Insurance Tahun 2021.",
			},
			{
				ID: 6,
				Path: "../../Website/assets/photos/sakina/brins/socialvalue/award/Tournament Golf HUT Ke-33 BRINS Tahun 2021.jpg",
				Description: "Tournament Golf HUT Ke-33 BRINS Tahun 2021.",
			},
		];
		this._arrayModelSocialValueType =
		[
			{
				ID: 0,
				Path: "../../assets/icons/sakina/socialvalue/icon_gcg.svg",
				Path2: "../../assets/icons/sakina/socialvalue/icon_gcg_white.svg",
				Description: "Tata Kelola Perusahaan",
			},
			{
				ID: 1,
				Path: "../../assets/icons/sakina/socialvalue/icon_risk.svg",
				Path2: "../../assets/icons/sakina/socialvalue/icon_risk_white.svg",
				Description: "Manajemen Risiko",
			},
			{
				ID: 2,
				Path: "../../assets/icons/sakina/socialvalue/icon_sustain.svg",
				Path2: "../../assets/icons/sakina/socialvalue/icon_sustain_white.svg",
				Description: "Keberlanjutan",
			},
			{
				ID: 3,
				Path: "../../assets/icons/sakina/socialvalue/icon_wbs.svg",
				Path2: "../../assets/icons/sakina/socialvalue/icon_wbs_white.svg",
				Description: "Sistem Pelaporan Pelanggaran",
			},
		];
		this._numberPageSizeSocialValueCSR = Math.ceil(this._arrayModelCSR.length/this._numberConstant.NUMBER_COMPARE_VALUE_THREE);
		this._arrayPageSizeSocialValueCSR = [];

		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;
		this.getScreenSize();
	}

	//#endregion


	//#region LISTENER

	@HostListener("window:resize", ["$event"])
	// eslint-disable-next-line no-unused-vars
	getScreenSize(event?): void
	{
		this._numberPixelHeight = window.innerHeight;
		this._numberPixelWidth = window.innerWidth;
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.createPageSizeSocialValueCSR();
		this._arrayModelCSR.reverse();
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	trackMilestone(index, modelMilestone: ContentModel)
	{
		return modelMilestone ? modelMilestone.ID : undefined;
	}

	setIndexContentBRINSWebsite(numberIndex: number): void
	{
		if(numberIndex > this._arrayModelContent5.length)
		{
			this._numberIndexBRINSUpdate = 0;
		}
		else
		{
			this._numberIndexBRINSUpdate = numberIndex;
		}
	}

	showAboutBRINS(numberIndex: number): void
	{
		this._numberIndexSocialValueCSR = numberIndex;
	}

	setSocialValueSubmain(numberIndex: number): void
	{
		this._numberSocialValueSubmainActive = numberIndex;
	}


	createPageSizeSocialValueCSR(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeSocialValueCSR; numberPageSize++)
		{
			this._arrayPageSizeSocialValueCSR.push(numberPageSize);
		}
	}

	paginateSocialValueCSR(numberPage: number): Array<ContentModel>
	{
		let numberPageSize: number = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;

		if(this._numberPixelWidth <= 490)
		{
			numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_ONE;
		}
		else if(this._numberPixelWidth <= 800)
		{
			numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_TWO;
		}
		else
		{
			numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;
		}

		return this._arrayModelCSR.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateSocialValueCSR(numberPaginate): void
	{
		this._numberIndexSocialValueCSR = numberPaginate;
	}

	goToSocialValueGCG(enumSocialValueType: ENUM_SOCIALVALUE_GCG): void
	{
		this._numberSocialValueGCGActive = enumSocialValueType;
	}
	goToSocialValueRiskManagement(enumSocialValueType: ENUM_SOCIALVALUE_RISKMANAGEMENT): void
	{
		this._numberSocialValueRiskActive = enumSocialValueType;
	}
	listenFileUpload(event): void
	{
		const arrayFileUpload: Array<File> = event.target.files;

		if (arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
		{

			for (const fileUpload of arrayFileUpload)
			{
				fileUpload.convertToBase64(
					(stringBase64) =>
					{
						this._modelClaimProposalGallery = new ClaimProposalGalleryModel();
						this._modelClaimProposalGallery.Format = fileUpload.type;
						this._modelClaimProposalGallery.Size = fileUpload.size;
						this._modelClaimProposalGallery.Name = fileUpload.name;
						this._modelClaimProposalGallery.Data = stringBase64;
						this._modelClaimProposalGallery.Extension = fileUpload.name.getFileExtension();

					},
					() =>
					{

					}
				);
			}
		}
		else
		{
		}
	}

	//#endregion


	//#region NAVIGATION

	goToLink(stringURL: string)
	{
		window.open(stringURL, "_blank");
	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	//#endregion

}
