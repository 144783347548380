/* eslint-disable max-len */
//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_CITIZENSHIP_STATUS, ENUM_GENDER_TYPE, ENUM_MARITAL_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL, ConvertToUserURL } from "src/app/functions/pipes/hyperlink.pipe";
import { RegexFunction } from "src/app/functions/regex.function";
import { AverageIncomePerYearModel } from "src/app/models/averageincomeperyear.model";
import { TableModel } from "src/app/models/bases/table.model";
import { BranchModel } from "src/app/models/branch.model";
import { BusinessRelationshipModel } from "src/app/models/businessrelationship.model";
import { CustomerModel } from "src/app/models/customer.model";
import { InsurancePurposeModel } from "src/app/models/insurancepurpose.model";
import { LocationModel } from "src/app/models/location.model";
import { OccupationModel } from "src/app/models/occupation.model";
import { PostalModel } from "src/app/models/postal.model";
import { ResponseModel } from "src/app/models/response.model";
import { SourceOfIncomeModel } from "src/app/models/sourceofincome.model";
import { UserModel } from "src/app/models/user.model";
import { UserGalleryModel } from "src/app/models/usergallery.model";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { OccuppationService } from "src/app/services/occupation.service";
import { OptionService } from "src/app/services/option.service";
import { PostalService } from "src/app/services/postal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-editprofile",
		templateUrl: "./editprofile.component.html",
		styleUrls: ["./editprofile.component.sass"],
	}
)

//#endregion


//#region CLASS

export class EditprofileComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	public _modelUserPublic: UserModel;
	public _modelUserPublicUpload: UserModel;
	public _modelUserPublicRequest: UserModel;
	public _modelUserPublicResponse: UserModel;
	public _modelCustomer: CustomerModel;
	public _modelUserGallery: UserGalleryModel;
	public _modelPostal: PostalModel;
	public _modelBranch: BranchModel;
	public _modelBranchResponse: BranchModel;
	public _modelTable: TableModel;
	public _modelOccupation: OccupationModel;
	public _modelSourceOfIncome: SourceOfIncomeModel;
	public _modelAverageIncomePerYear: AverageIncomePerYearModel;
	public _modelBusinessRelationship: BusinessRelationshipModel;
	public _modelInsurancePurpose: InsurancePurposeModel;

	public _booleanPostalCodeValid: boolean;

	public _numberConstant = NumberConstant;

	public _enumStringConstant = StringConstant;
	public _enumGenderType = ENUM_GENDER_TYPE;
	public _enumCitizenship = ENUM_CITIZENSHIP_STATUS;

	public _arrayModelBranch: Array<BranchModel>;
	public _arrayGalleryModel: Array<number>;
	public _arrayModelOccupation: Array<OccupationModel>;
	public _arrayModelSourceOfIncome: Array<SourceOfIncomeModel>;
	public _arrayModelAverageIncomePerYear: Array<AverageIncomePerYearModel>;
	public _arrayModelBusinessRelationship: Array<BusinessRelationshipModel>;
	public _arrayModelInsurancePurpose: Array<InsurancePurposeModel>;
	public _arrayEnumMaritalStatus: Array<ENUM_MARITAL_STATUS>;

	public _dateNow: Date;

	public _stringImageUrl: string | ArrayBuffer;
	public _stringFileTypeAllowed: string = RuleConstant.STRING_FILE_TYPE_ALLOWED;
	public _stringConstant = StringConstant;

	public _fileToUpload: any;

	public _pipeConvertToUserURL: ConvertToUserURL;

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(private _serviceCustomer: CustomerService, private _servicePostal: PostalService, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService, private _serviceOccupation: OccuppationService, private _serviceOption: OptionService)
	{
		super(serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicUpload = new UserModel();
		this._modelUserPublicRequest = new UserModel();
		this._modelUserPublicResponse = new UserModel();
		this._modelCustomer = new CustomerModel();
		this._modelUserGallery = new UserGalleryModel();
		this._modelPostal = new PostalModel();
		this._modelBranch = new BranchModel();
		this._modelBranchResponse = new BranchModel();
		this._modelTable = new TableModel();
		this._modelOccupation = new OccupationModel();
		this._modelSourceOfIncome = new SourceOfIncomeModel();
		this._modelAverageIncomePerYear = new AverageIncomePerYearModel();
		this._modelBusinessRelationship = new BusinessRelationshipModel();
		this._modelInsurancePurpose = new InsurancePurposeModel();

		this._arrayModelBranch = [];
		this._arrayGalleryModel = [];
		this._arrayModelOccupation = [];
		this._arrayModelSourceOfIncome = [];
		this._arrayModelAverageIncomePerYear = [];
		this._arrayModelBusinessRelationship = [];
		this._arrayModelInsurancePurpose = [];
		this._arrayEnumMaritalStatus =
		[
			ENUM_MARITAL_STATUS.Single,
			ENUM_MARITAL_STATUS.Married,
			ENUM_MARITAL_STATUS.Divorce,
			ENUM_MARITAL_STATUS.Widow
		];

		this._dateNow = new Date();

		this._booleanPostalCodeValid = false;

		this._stringImageUrl = "";

		this._pipeConvertToUserURL = new ConvertToUserURL();

		this._functionRegex = new RegexFunction();
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.callSelectProfileCustomerByAttributes(this);
	}

	//#endregion


	//#region WEB SERVICE

	private callSelectProfileCustomerByAttributes(componentCurrent: this): void
	{
		this._modelUserPublic.Email = this._modelUserSignIn.Email;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._serviceCustomer.selectProfileCustomerByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelUserPublic.setModelFromString(modelResponse.Data);

						if(componentCurrent._modelUserPublic.modelBranch !== undefined)
						{
							componentCurrent._modelBranchResponse.Name = componentCurrent._modelUserPublic.modelBranch.Name;
						}
						if(componentCurrent._modelUserPublic.OccupationName !== undefined)
						{
							componentCurrent._modelOccupation.Name = componentCurrent._modelUserPublic.OccupationName;
						}
						if(componentCurrent._modelUserPublic.SourceOfIncomeName !== undefined)
						{
							componentCurrent._modelSourceOfIncome.Name = componentCurrent._modelUserPublic.SourceOfIncomeName;
						}
						if(componentCurrent._modelUserPublic.AverageIncomePerYearName !== undefined)
						{
							componentCurrent._modelAverageIncomePerYear.Name = componentCurrent._modelUserPublic.AverageIncomePerYearName;
						}
						if(componentCurrent._modelUserPublic.BusinessRelationshipName !== undefined)
						{
							componentCurrent._modelBusinessRelationship.Name = componentCurrent._modelUserPublic.BusinessRelationshipName;
						}
						if(componentCurrent._modelUserPublic.InsurancePurposeName !== undefined)
						{
							componentCurrent._modelInsurancePurpose.Name = componentCurrent._modelUserPublic.InsurancePurposeName;
						}
						else
						{
						}

						componentCurrent.callSelectLocationByPostalCode();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSelectProfileCustomerByAttributes(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelUserPublic);
	}

	public callUpdateCustomerProfile(componentCurrent: this): void
	{
		this._modelUserPublic.modelDivision = undefined;
		this._modelUserPublic.modelHandshake = undefined;
		this._modelUserPublic.listModelLetterRole = undefined;
		this._modelUserPublic.listModelUserGroupAccess = undefined;
		this._modelUserPublic.listModelUserGallery = undefined;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelResponseValidation: ResponseModel = this._modelUserPublic.validateFormUser();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.updateCustomerProfile
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelUserPublicResponse.setModelFromString(modelResponse.Data);
						}
						else
						{
						}
						componentCurrent.callUploadUserWebsiteCustomer(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserPublic);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callUploadUserWebsiteCustomer(componentCurrent: this): void
	{
		this._modelUserPublicRequest.listModelUserGallery = this._modelUserPublicUpload.listModelUserGallery;
		this._modelUserPublicRequest.Token = this._modelUserSignIn.Token;

		this._functionUserInterface.setLoadingProgress(1);

		if (this._modelUserPublicRequest.listModelUserGallery === undefined || this._modelUserPublicRequest.listModelUserGallery === null || this._modelUserPublicRequest.listModelUserGallery.length <= 0)
		{
			componentCurrent._functionUserInterface.updateLoadingProgress();
			componentCurrent.callSelectProfileCustomerByAttributes(componentCurrent);
		}
		else
		{
			this._serviceCustomer.uploadUserWebsiteCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
					window.location.reload();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserPublicRequest);
		}

	}

	private callSelectLocationByPostalCode(): void
	{
		const componentCurrent: EditprofileComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		if (componentCurrent._modelUserPublic.PostalCode !== null && componentCurrent._modelUserPublic.PostalCode !== undefined)
		{
			componentCurrent._modelPostal.Code = componentCurrent._modelUserPublic.PostalCode;
		}
		else
		{

		}

		this._servicePostal.selectLocationByPostalCode
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelLocationResponse: LocationModel = new LocationModel();
						modelLocationResponse.setModelFromString(modelResponse.Data);

						componentCurrent._modelUserPublic.setLocationFromPostal(modelLocationResponse);
						componentCurrent._booleanPostalCodeValid = true;

						componentCurrent.callSelectBranchForCustomer();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					// componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
					{
						componentCurrent._modelUserPublic.CountryName = "";
						componentCurrent._modelUserPublic.ProvinceName = "";
						componentCurrent._modelUserPublic.CityName = "";
					});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectLocationByPostalCode(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelPostal);
	}

	private callSelectBranchForCustomer(): void
	{
		const componentCurrent: EditprofileComponent = this;
		componentCurrent._modelTable.Search = JSON.stringify(this._modelUserPublic.BranchName);
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		this._modelTable.RowPerPage = 100;

		this._serviceBranch.selectBranchForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectOccupationForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelTable);
	}

	private callSelectOccupationForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOccupation.selectOccupationForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelOccupation = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectSourceOfIncomeForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectOccupationForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectSourceOfIncomeForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectSourceOfIncomeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelSourceOfIncome = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectAverageIncomePerYearForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectSourceOfIncomeForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectAverageIncomePerYearForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectAverageIncomePerYearForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelAverageIncomePerYear = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectBusinessRelationshipForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAverageIncomePerYearForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectBusinessRelationshipForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectBusinessRelationshipForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelBusinessRelationship = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectInsurancePurposeForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBusinessRelationshipForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectInsurancePurposeForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectInsurancePurposeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelInsurancePurpose = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectInsurancePurposeForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	protected setViewFromTop(): void
	{
		let top = document.getElementById("divMainDashboard");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	onSelectFile(event)
	{
		if (event.target.files && event.target.files[0])
		{
			const reader = new FileReader();

			// read file as data url
			reader.readAsDataURL(event.target.files[0]);

			reader.onload = (events) =>
			{ // called once readAsDataURL is completed
				this._modelUserGallery.Data = undefined;
				this._stringImageUrl = events.target?.result as string;
				this._modelUserGallery.Data = this._stringImageUrl;
			};
		}
	}

	deletePhotoURL()
	{
		if (this._modelUserPublic.listModelUserGallery)
		{
			for (const modelUserPublic of this._modelUserPublic.listModelUserGallery)
			{
				if (modelUserPublic.State === 0)
				{
					modelUserPublic.Data = undefined;
				}
				else
				{

				}
			}
		}
	}

	//#endregion


	//#region NAVIGATION

	goToEditEmail(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home"]);
		}
		else
		{
			this._router.navigate(["editemail", stringToken]);
		}
	}

	goToEditMobilePhone(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home"]);
		}
		else
		{
			this._router.navigate(["editmobilephone", stringToken]);
		}
	}

	//#endregion


	//#region LISTENER

	selectPostalCode(): void
	{
		if(this._modelUserPublic.PostalCode?.length === this._numberConstant.NUMBER_COMPARE_VALUE_FIVE)
		{
			this.callSelectLocationByPostalCode();
		}
		else
		{

		}
	}

	listenFileUpload(event): void
	{
		const fileUploadFirstItem = event.target.files[0];
		const reader = new FileReader();
		// read file as data url
		reader.readAsDataURL(event.target.files[0]);

		reader.onload = (events) =>
		{ // called once readAsDataURL is completed

			this._stringImageUrl = events.target?.result as string;
			this._modelUserPublicResponse.PhotoURL = this._stringImageUrl;
			if (this._modelUserPublic.listModelUserGallery)
			{
				for (const modelUserPublic of this._modelUserPublic.listModelUserGallery)
				{
					modelUserPublic.Data = this._stringImageUrl;
				}
			}
		};


		const arrayFileUpload: Array<File> = event.target.files;

		let modelResponse: ResponseModel = new ResponseModel();

		if (arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
		{
			let modelUserGallery: UserGalleryModel;

			for (const fileUpload of arrayFileUpload)
			{
				fileUpload.convertToBase64(
					(stringBase64) =>
					{
						if (this._modelUserPublicUpload.validateAddForUpload())
						{
							const arraySplitName: Array<any> = fileUploadFirstItem.name.split(".");

							this._modelUserPublicUpload.listModelUserGallery = [];

							modelUserGallery = new UserGalleryModel();
							modelUserGallery.Format = fileUpload.type;
							modelUserGallery.Size = fileUpload.size;
							modelUserGallery.Name = arraySplitName[0];
							modelUserGallery.Data = stringBase64;
							modelUserGallery.Extension = fileUpload.name.getFileExtension();

							modelResponse = modelUserGallery.validateAdd();

							if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
							{
								this._modelUserPublicUpload?.listModelUserGallery?.push(modelUserGallery);
							}
							else
							{
								this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}

							modelUserGallery = new UserGalleryModel();
						}
						else
						{
						}
					},
					() =>
					{

					}
				);
			}
		}
		else
		{
		}
	}

	//#endregion


	//#region GETTER

	getBirthDate(event): void
	{
		const maxDate: Date = this._dateNow;
		maxDate.setDate(maxDate.getDate() + 1);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Tanggal Lahir";
		modelResponse.MessageContent = "Tanggal lahir tidak boleh lebih dari hari ini!";

		if(event.value > maxDate)
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			this._modelUserPublic.BirthDate = event.value.clearUTC();
		}
	};

	getImage(stringURL): string
	{
		return this._pipeConvertToUserURL.transform(stringURL);
	}

	public getMaritalStatus(enumMaritalStatus: ENUM_MARITAL_STATUS): string
	{
		if (enumMaritalStatus === ENUM_MARITAL_STATUS.Single)
		{
			return "Lajang";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Married)
		{
			return "Menikah";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Divorce)
		{
			return "Cerai";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Widow)
		{
			return "Duda / Janda";
		}
		else
		{
			return "";
		}
	}

	//#endregion


	//#region SETTER

	setGender(enumGender: ENUM_GENDER_TYPE): void
	{
		if(enumGender !== undefined)
		{
			this._modelUserSignIn.Gender = enumGender;
			this._modelUserPublic.Gender = enumGender;
		}
	}

	setBranch(modelBranch: BranchModel): void
	{
		if(modelBranch !== undefined)
		{
			this._modelUserPublic.BranchID = modelBranch.ID;
			this._modelUserPublic.BranchCode = modelBranch.Code;
			this._modelUserPublic.BranchName = modelBranch.Name;

			this._modelBranch.ID = modelBranch.ID;
			this._modelBranch.Code = modelBranch.Code;
			this._modelBranch.Name = modelBranch.Name;

			this._modelUserPublic.modelBranch = this._modelBranch;
		}
	}

	setCitizenship(enumCitizenship: ENUM_CITIZENSHIP_STATUS): void
	{
		if(enumCitizenship !== undefined)
		{
			this._modelUserPublic.CitizenshipStatus = enumCitizenship;
		}
	}

	setOccupation(modelOccupation: OccupationModel): void
	{
		this._modelUserPublic.OccupationCode = modelOccupation.Code;
		this._modelUserPublic.OccupationName = modelOccupation.Name;
	}

	setAverageIncomePerYear(modelAverageIncomePerYear: AverageIncomePerYearModel): void
	{
		this._modelUserPublic.AverageIncomePerYearCode = modelAverageIncomePerYear.Code;
		this._modelUserPublic.AverageIncomePerYearName = modelAverageIncomePerYear.Name;
	}

	setSourceOfIncome(modelSourceOfIncome: SourceOfIncomeModel): void
	{
		this._modelUserPublic.SourceOfIncomeCode = modelSourceOfIncome.Code;
		this._modelUserPublic.SourceOfIncomeName = modelSourceOfIncome.Name;
	}

	setBusinessRelationship(modelBusinessRelationship: BusinessRelationshipModel): void
	{
		this._modelUserPublic.BusinessRelationshipCode = modelBusinessRelationship.Code;
		this._modelUserPublic.BusinessRelationshipName = modelBusinessRelationship.Name;
	}

	setInsurancePurpose(modelInsurancePurpose: InsurancePurposeModel): void
	{
		this._modelUserPublic.InsurancePurposeCode = modelInsurancePurpose.Code;
		this._modelUserPublic.InsurancePurposeName = modelInsurancePurpose.Name;
	}

	setBooleanDataSharingConsent(event): void
	{
		this._modelUserPublic.DataSharingConsent = event.checked;
	}

	setBooleanProductPromotionConsent(event): void
	{
		this._modelUserPublic.ProductPromotionConsent = event.checked;
	}

	//#endregion

}
//#endregion
