//#region IMPORT

import { ENUM_BRINSUPDATE_PRIORITY, ENUM_BRINSUPDATE_TYPE } from "../constants/enum.constant";
import { AwardModel } from "./award.model";
import { BaseModel } from "./bases/base.model";
import { CommunityModel } from "./community.model";
import { DiscountModel } from "./discount.model";
import { FinancialLiteracyModel } from "./financialliteracy.model";
import { NewsFeedModel } from "./newsfeed.model";
import { TestimonyModel } from "./testimony.model";

//#endregion


//#region CLASS

export class BRINSUpdateModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Eka Saputra.
		Created on : Wednesday, 30 March 2022. 			Updated on : Wednesday, 30 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	Type?: ENUM_BRINSUPDATE_TYPE;
	Priority?: ENUM_BRINSUPDATE_PRIORITY;

	Title?: string;
	Content?: string;
	PublisherName?: string;
	PublisherCity?: string;
	EditorName?: string;
	EditorEmail?: string;
	PublishedOn?: Date;
	SourceURL?: string;

	//#region MODEL

	modelAward?: AwardModel;
	modelCommunity?: CommunityModel;
	modelDiscount?: DiscountModel;
	modelFinancialLiteracy?: FinancialLiteracyModel;
	modelNewsFeed?: NewsFeedModel;
	modelTestimony?: TestimonyModel;

	//#endregion

	/* Attribute - END */
}

//#endregion