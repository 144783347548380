<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>Nilai</h1><h1> Sosial</h1>
			</div>
			<div class="DivContentDescription">
				<p>BRINS terus berkomitmen dalam menjaga keseimbangan di setiap aspek dengan mengedepankan nilai sosial dan memperkuat implementasi LST (Lingkungan, Sosial, dan Tata Kelola Perusahaan) dengan menjalankan berbagai inisiatif dan inovasi.</p>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
				<img [src]="getImageFromBroadcastURL('../../Website/assets/background/sakina/brins/socialvalue/header_social.jpg')">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divBodySocialValueCEO" class="DivBodySiteContainer">
	<div class="DivContent2Column">
		<div class="DivContentImage DivColumnContainer">
			<!-- <img class="ImageShapeCEO" src="../../assets/shapes/sakina/shape_ceo.svg"> -->
			<img [src]="getImageFromBroadcastURL('../../Website/assets/photos/sakina/brins/socialvalue/photo_dirut_social.png')">
		</div>
		<div class="DivContent">
			<div class="DivContentMessageCEO">
				<p>Pesan dari</p>
				<h2>R. Budi Legowo</h2>
				<label>CEO BRI Insurance</label>
				<p>
					"Kami berkomitmen untuk menerapkan Tata Kelola Perusahaan yang baik dan menerapkan proses manajemen resiko dalam rangka mengoptimalkan nilai perusahaan untuk seluruh
					pemangku kepentingan, terutama pemegang polis, tertanggung, dan/atau seluruh pihak yang menerima keuntungan berdasarkan hukum, regulasi dan nilai etika."
				</p>
			</div>
		</div>
	</div>
</div>


<div id="divBodySocialValueInformation" class="DivBodySiteContainer">
	<div class="DivContentMain" id="divContainMainHeader">
		<div class="DivContentBoxDetail" (click)="setSocialValueSubmain(i)" [ngClass]="{'DivContentBoxDetailShow' : _numberSocialValueSubmainActive === i }" *ngFor="let modelContentSocialValue of _arrayModelSocialValueType; let i = index ">
			<div class="DivContentDetail">
				<img *ngIf=" _numberSocialValueSubmainActive === i" [src]="modelContentSocialValue.Path2">
				<img *ngIf=" _numberSocialValueSubmainActive !== i" [src]="modelContentSocialValue.Path">
				<div class="DivContentBoxDescription">
					<h3> {{ modelContentSocialValue.Description | trimShort }} </h3>
				</div>
			</div>
		</div>
	</div>
	<div class="DivContentDescription" *ngIf="_numberSocialValueSubmainActive === _enumSocialValueSubmain.GoodCorporateGovernance">
		<h1>Tata Kelola Perusahaan yang Baik</h1>
		<div id="divBodyTabAbout" class="DivBodyContainer">
			<div class="DivContentMain">
				<div class="DivContentTabAbout">
					<button (click)="goToSocialValueGCG(_enumSocialValueGCG.Pedoman)" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Pedoman ? 'ButtonActive' : 'ButtonInActive'"> Pedoman </button>
					<button (click)="goToSocialValueGCG(_enumSocialValueGCG.Gratifikasi)" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Gratifikasi ? 'ButtonActive' : 'ButtonInActive'">Anti Gratifikasi & Anti Fraud</button>
					<button (click)="goToSocialValueGCG(_enumSocialValueGCG.APU)" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.APU ? 'ButtonActive' : 'ButtonInActive'"> APU - PPT </button>
					<button (click)="goToSocialValueGCG(_enumSocialValueGCG.Kepentingan)" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Kepentingan ? 'ButtonActive' : 'ButtonInActive'"> Benturan Kepentingan </button>
				</div>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Pedoman? 'DivShow' : 'DivHide'">
				<h3>Pedoman GCG</h3>
				<p>
					Perusahaan menyadari pentingnya penerapan Tata Kelola Perusahaan yang Baik <em>(Good Corporate Governance)</em>, terutama guna mendapatkan kepercayaan dari <em>stakeholders</em>.
					Hal ini tercermin dari komitmen GCG di perusahaan yang diterapkan sejak tahun 1999.
				</p>
				<p class="ParagraphBold">
					Maksud dan tujuan penerapan GCG di Perseroan adalah sebagai berikut:
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Memaksimalkan nilai Perseroan agar memiliki daya saing yang kuat, sehingga mampu mempertahankan keberadaannya dan dapat hidup berkelanjutan untuk mencapai maksud dan tujuan Perseroan.
						</p>
					</li>
					<li>
						<p>
							Mendorong pengelolaan Perseroan secara profesional, efisien dan efektif, serta memberdayakan fungsi dan meningkatkan kemandirian Perseroan.
						</p>
					</li>
					<li>
						<p>
							Mendorong agar manajemen Perseroan dalam membuat keputusan dan menjalankan tindakan dilandasi nilai moral yang tinggi dan kepatuhan terhadap peraturan perundang-undangan, serta kesadaran akan adanya tanggung jawab sosial perusahaan terhadap <em>stakeholders</em> maupun kelestarian lingkungan Perseroan.
						</p>
					</li>
					<li>
						<p>
							Meningkatkan kontribusi Perseroan terhadap perekonomian nasional.
						</p>
					<li>
						<p>
							Meningkatkan iklim yang kondusif bagi perkembangan nilai investasi Perseroan.
						</p>
					</li>
				</ol>
				<p class="ParagraphBold">
					BRI Insurance telah memiliki kebijakan/perangkat GCG yang diharapkan dapat menjadi acuan dalam mengimplementasikan GCG di BRI Insurance, yaitu:
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Pedoman Tata Kelola  <em>(Good Corporate Governance)</em> PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Pedoman Tata Tertib Direksi (Persero) PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Audit Committee Charter (Piagam Komite Audit) PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Internal Audit Charter (Piagam Audit Internal) PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Kebijakan Kepatuhan Hukum dan Peraturan Perundang-undangan PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Kebijakan Mekanisme Benturan Kepentingan <em>(Conflict Of Interest)</em> PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Kebijakan Larangan Pemberian dan Penerimaan Hadiah Atau Suap (Pengendalian Gratifikasi) di Lingkungan PT BRI Asuransi Indonesia.
						</p>
					</li>
					<li>
						<p>
							Mekanisme Pelaporan Penyimpangan atau pelanggaran <em>(Whistleblowing)</em>.
						</p>
					</li>
					<li>
						<p>
							Kebijakan Kode Etik dan Etika Kerja <em>(Code of Conduct)</em>.
						</p>
					</li>
					<li>
						<p>
							Piagam Komite Kebijakan Risiko.
						</p>
					</li>
					<li>
						<p>
							Piagam Komite Remunerasi dan Nominasi.
						</p>
					</li>
					<li>
						<p>
							Piagam Komite Kebijakan Tata Kelola Perusahaan.
						</p>
					</li>
				</ol>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Gratifikasi? 'DivShow' : 'DivHide'">
				<h3>Pengendalian Gratifikasi dan Anti Penyuapan</h3>
				<p class="ParagraphBold">
					Pengendalian Gratifikasi
				</p>
				<p>
					Insan Perusahaan dilarang memberi dan/atau menerima apapun yang tidak sah dari pihak manapun termasuk pemangku kepentingan <em>(stakeholders)</em> dengan maksud untuk memberikan keuntungan pribadi dan merugikan kepentingan Perusahaan, seperti memberi dan/atau meminta hadiah, <em>entertainment</em> atau dalam bentuk lainnya.
				</p>
				<p>
					Secara lebih rinci hal tersebut diatur tersendiri dalam Kebijakan Larangan Pemberian dan Penerimaan Hadiah atau Suap (Pengendalian Gratifikasi) di Lingkungan PT. BRI Asuransi Indonesia yang dituangkan dalam Surat Keputusan Bersama Dewan Komisaris dan Direksi.
				</p>
				<p>
					Komitmen Bersama Penerapan Sistem Manajemen Anti Penyuapan PT. BRI Asuransi Indonesia menerapkan Kebijakan Anti Penyuapan sesuai Pedoman Sistem Manajemen Anti Penyuapan (SMAP) dengan ketentuan-ketentuan yang tercantum.
				</p>
				<p class="ParagraphBold">
					Kebijakan Anti Penyuapan
				</p>
				<p>
					Dalam rangka menerapkan Sistem Manajemen Anti Penyuapan (SMAP) berdasarkan ISO 37001:2016 sebagai upaya pencegahan dan pemberantasan korupsi, kolusi dan nepotisme, dengan ini kami Dewan Komisaris, Direksi dan seluruh pekerja PT BRI Asuransi Indonesia berkomitmen:
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Melarang penyuapan di lingkungan Perusahaan dengan berperan secara proaktif dalam upaya pencegahan dan pemberantasan korupsi, kolusi dan nepotisme.
						</p>
					</li>
					<li>
						<p>
							Tunduk dan patuh pada peraturan perundang-undangan dan regulasi terkait dengan Sistem Manajemen Anti Penyuapan yang berlaku sesuai dengan tujuan Perusahaan.
						</p>
					</li>
					<li>
						<p>
							Menyediakan kerangka kerja Sistem Manajemen Anti Penyuapan serta melakukan pengawasan terhadap pelaksanaan Komitmen Anti Penyuapan dan Sasaran Anti Penyuapan.
						</p>
					</li>
					<li>
						<p>
							Mendorong peningkatkan kepedulian dengan itikad baik, atau atas dasar keyakinan yang wajar, tanpa takut tindakan balasan.
						</p>
					</li>
					<li>
						<p>
							Mendorong peningkatan berkelanjutan terhadap Sistem Manajemen Anti Penyuapan.
						</p>
					</li>
					<li>
						<p>
							Memastikan Fungsi Kepatuhan Anti Penyuapan dalam menjalankan tugas dan wewenang dilakukan secara independen dan bebas dari konflik kepentingan.
						</p>
					</li>
					<li>
						<p>
							Bertindak tegas terhadap segala bentuk pelanggaran pelanggaran atas ketentuan sistem manajemen anti penyuapan dan menerima sanksi sesuai peraturan perusahaan dan perundang-undangan yang berlaku.
						</p>
					</li>
				</ol>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.APU? 'DivShow' : 'DivHide'">
				<h3>Anti Pencucian Uang dan Pencegahan Pendanaan Terorisme</h3>
				<p>
					Dasar acuan utama penerapan program APU-PPT adalah Peraturan OJK No. 12/POJK.01/2017 dan Peraturan OJK No. 23/POJK.01/2019 tentang Penerapan Program APU dan PPT di Sektor Jasa Keuangan, Surat Edaran OJK No.37/POJK.05/2017 tentang Penerapan Program Pencucian Uang dan Pencegahan Pendanaan Terorisme Di Sektor Jasa Keuangan Non-Bank.
				</p>
				<p>
					Perusahaan telah memiliki Pedoman Penerapan Program Anti Pencucian Uang (APU) dan Pencegahan Pendanaan Terorisme (PPT) PT. BRI Asuransi Indonesia sebagai dasar acuan pelaksanaan APU - PPT di Perusahaan dan selalu dilakukan pengkinian terhadap pedoman tersebut, serta melakukan sosialisasi secara berkala kepada para pekerja di lingkungan PT. BRI Asuransi Indonesia.
				</p>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueGCGActive ===  _enumSocialValueGCG.Kepentingan? 'DivShow' : 'DivHide'">
				<h3>Penanganan Benturan Kepentingan</h3>
				<p>
					Dalam rangka membangun kerjasama yang harmonis dan meningkatkan nilai Perusahaan, Perusahaan mengedepankan sikap yang tegas terhadap penanganan Benturan Kepentingan yang terjadi di Perusahaan. Hal ini dimaksudkan agar dapat terciptanya pengelolaan Perusahaan yang baik, serta hubungan yang harmonis dengan seluruh Pemangku Kepentingan <em>(Stakeholder)</em> maupun pihak-pihak lainnya dalam pelaksanaan kerjasama dan interaksi dengan Perusahaan.
				</p>
				<p>
					Perusahaan telah memiliki Pedoman Benturan Kepentingan yang berpedoman kepada peraturan Perundang-undangan yang berlaku, serta diselaraskan dengan Pedoman Tata Kelola Perusahaan <em>(Code of Corporate Governance)</em> dan Pedoman Kode Etik dan Etika Kerja <em>(Code of Conduct)</em>, serta nilai-nilai yang berlaku di Perusahaan. Pedoman Benturan Kepentingan BRI Insurance disosialisasikan dan dievaluasi penerapannya secara berkelanjutan kepada seluruh Insan BRI Insurance.
				</p>
			</div>
		</div>
	</div>
	<div class="DivContentDescription" *ngIf="_numberSocialValueSubmainActive === _enumSocialValueSubmain.RiskManagement">
		<h1>Manajemen Risiko</h1>
		<div id="divBodyTabAbout" class="DivBodyContainer">
			<div class="DivContentMain">
				<div class="DivContentTabAbout">
					<button (click)="goToSocialValueRiskManagement(_enumSocialValueRiskManagement.Pedoman)" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.Pedoman ? 'ButtonActive' : 'ButtonInActive'"> Pedoman </button>
					<button (click)="goToSocialValueRiskManagement(_enumSocialValueRiskManagement.AntiFraud)" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.AntiFraud ? 'ButtonActive' : 'ButtonInActive'"> Anti-Fraud </button>
					<button (click)="goToSocialValueRiskManagement(_enumSocialValueRiskManagement.BCM)" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.BCM ? 'ButtonActive' : 'ButtonInActive'"> BCM </button>
					<button (click)="goToSocialValueRiskManagement(_enumSocialValueRiskManagement.Covid19)" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.Covid19 ? 'ButtonActive' : 'ButtonInActive'"> Respon COVID-19 </button>
				</div>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.Pedoman ? 'DivShow' : 'DivHide'">
				<h3>Pedoman Manajemen Risiko</h3>
				<p>
					Perusahaan menerapkan Manajemen Risiko dalam kegiatan usahanya sesuai dengan tujuan, kebijakan bisnis, ukuran, dan kompleksitas bisnis perusahaan. Penerapan Manajemen Risiko Perusahaan didukung dengan struktur organisasi Manajemen Risiko, yang mencakup Fungsi Manajemen Risiko dan  Komite Manajemen Risiko. Penerapan Manajemen Risiko yang efektif juga didukung oleh peran <em>Risk Champion</em> yang bertanggung jawab memastikan proses manajemen risiko berjalan di setiap unit kerja.
				</p>
				<p class="ParagraphBold">
					Penerapan manajemen risiko secara efektif diLakukan perusahaan melalui antara:
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Penetapan kerangka Manajemen Risiko yang mencakup strategi, kebijakan dan prosedur Manajemen Risiko, dan batasan Risiko yang jelas sesuai dengan visi, misi, dan strategi Perusahaan.
						</p>
					</li>
					<li>
						<p>
							Identifikasi dan pengukuran seluruh risiko dilakukan secara berkala, termasuk risiko yang melekat dalam kegiatan usaha Perusahaan.
						</p>
					</li>
					<li>
						<p>
							Pengendalian Risiko yang selaras dengan eksposur Risiko perusahaan, <em>risk appetite</em>, dan <em>risk tolerance</em>.
						</p>
					</li>
					<li>
						<p>
							Pemantauan atas risiko yang telah diidentifikasi, diukur dan dikendalikan, dalam bentuk <em>risk register</em>.
						</p>
					<li>
						<p>
							Penerapan sistem pengendalian internal dilakukan secara efektif dalam rangka mengelola Risiko Perusahaan, serta kepatuhan terhadap kebijakan dan prosedur perusahaan.
						</p>
					</li>
				</ol>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.AntiFraud ? 'DivShow' : 'DivHide'">
				<h3>Anti-Fraud</h3>
				<p>
					BRI insurance memiliki pedoman pengendalian <em>fraud</em> berupa strategi anti <em>fraud</em>. Perusahaan menerapkan strategi anti <em>fraud</em> dalam rangka pengendalian <em>fraud</em> dengan mempertimbangkan karakteristik potensi <em>fraud</em> secara komprehensif.
				</p>
				<p class="ParagraphBold">
				Perusahaan menerapkan strategi anti <em>fraud</em> melalui beberapa program sebagai berikut :
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Menerapkan langkah-langkah pencegahan untuk mengurangi kemungkinan risiko terjadinya <em>fraud</em> melalui penandatanganan pakta integritas pekerja sebagai bentuk pernyataan anti-<em>fraud</em>.
						</p>
					</li>
					<li>
						<p>
							Pelaksanaan program peningkatan <em>risk culture</em> bagi seleuruh pekerja melalui buklet GRC Flash, GRC Greetings, seminar dan pelatihan anti-<em>fraud</em>.
						</p>
					</li>
					<li>
						<p>
							Pelaksanaan <em>Know Your Employee</em> terhadap pekerja perusahaan dimulai dari proses <em>screening</em> pada saat rekrutmen.
						</p>
					</li>
					<li>
						<p>
							Identifikasi dan analisis aktivitas Perusahaan yang berpotensi menimbulkan dampak kerugian bagi Perusahaan dalam bentuk <em>fraud risk assessment</em> secara periodik.
						</p>
					</li>
					<li>
						<p>
							Identifikasi dan deteksi insiden <em>fraud</em> melalui penerapan kebijakan dan mekanisme <em>whistleblowing</em>, dan pemantauan indikator atas potensi risiko <em>fraud</em>.
						</p>
					</li>
				</ol>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.BCM ? 'DivShow' : 'DivHide'">
				<h3>Manajemen Kelangsungan Bisnis</h3>
				<figure>
				<img [src]="getImageFromBroadcastURL('../../Website/assets/photos/sakina/brins/socialvalue/photo_risk_bcm.jpg')" alt="PT. BRI Asuransi - BCM">
				</figure>
				<p>
					BRI Insurance berkomitmen untuk menerapkan Manajemen Kelangsungan Bisnis, yaitu pengelolaan yang terintegrasi dan menyeluruh untuk menjamin kelangsungan operasional Perusahaan. Perusahaan telah memiliki ketentuan BCM yang meliputi pedoman keselamatan jiwa, kelangsungan bisnis, dan pemulihan IT. Pengujian dan evaluasi atas pelaksanaan BCM dilakukan oleh perusahaan secara berkala sebagai bentuk evaluasi atas penerapan BCM. Dalam rangka menyempurnakan BCM perusahaan. Dalam rangka memastikan penerapan BCM perusahaan, perbaikan terus dilakukan untuk menguji keefektifan BCM.
				</p>
				<p>
					Dalam rangka penerapan kesehatan dan keselamatan kerja (K3), perusahaan secara berkala memantau kesiapan peralatan tanggap darurat sebagai bentuk penilaian ancaman kesehatan dan keselamatan kerja. Evaluasi terhadap penerapan dimaksud juga dilakukan dalam rangka menerapkan  K3 yang efektif bagi perusahaan.
				</p>
			</div>
			<div class="DivContentMain" [ngClass]="_numberSocialValueRiskActive ===  _enumSocialValueRiskManagement.Covid19 ? 'DivShow' : 'DivHide'">
				<h3>Respon terhadap COVID-19</h3>
				<p>
					BRI Insurance telah merespon COVID-19 sedari awal sejak ditetapkan sebagai <em>Pandemic</em> oleh <em>World Health Organization (WHO)</em>. Perusahaan telah menetapkan langkah-Langkah penanggulangan dampak bencana COVID-19 sebagai bentuk pengaktifan protokol BCM dan K3.
				</p>
				<p class="ParagraphBold">
					Beberapa hal yang telah dilakukan perusahaan dalam merespon COVID-19 antara lain:
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Membentuk Tim Unit Pencegahan, Pengendalian dan Penanggulangan <em>Corona Virus Disease</em> 2019 (COVID-19) yang berfungsi meningkatkan kesiapsiagaan dan kemampuan perusahaan dalam mencegah, mendeteksi, dan merespon COVID-19, termasuk percepatan penanganan COVID-19.
						</p>
					</li>
					<li>
						<p>
							Mengaktifkan protokol penanganan COVID-19 sebagai bentuk pengaktifan Manajemen Kelangsungan Bisnis dan K3 guna pencegahan dan antisipasi untuk mengurangi dampak risiko penularan terhadap customer,  pekerja dan keluarga pekerja.
						</p>
					</li>
					<li>
						<p>
							Menerapkan beberapa kebijakan dalam rangka pengendalian dan pencegahan COVID-19 seperti pemberlakukan pembatasan kegiatan di luar rumah <em>(social distancing)</em> melalui pembatasan jam kantor <em>(shifting operation)</em>, pembatasan perjalanan dinas, dan penerapan <em>Work From Home</em> (WFH).
						</p>
					</li>
					<li>
						<p>
							Beberapa program yang dilakukan perusahaan dalam rangka pengendalian dan pencegahan COVID-19 antara lain program vaksin untuk pekerja dan keluarga pekerja, dan penyediaan sarana antar jemput bagi pekerja.
						</p>
					</li>
				</ol>
			</div>
		</div>
	</div>
	<div class="DivContentDescription" *ngIf="_numberSocialValueSubmainActive === _enumSocialValueSubmain.Sustainability">
		<h1>Keberlanjutan</h1>
		<div id="divBodyTabAbout" class="DivBodyContainer">
			<div class="DivContentMain">
				<p>
					Perusahaan berkomitmen untuk mewujudkan bisnis yang berkelanjutan dengan mengintegrasikan faktor lingkungan, sosial dan tata kelola (LST). Perusahaan menjalankan operasinya berdasarkan prinsip-prinsip LST dan mengimplementasikan program Rencana Aksi Keuangan Berkelanjutan.
				</p>
				<p class="ParagraphBold">
					Komitmen perusahaan dalam rangka mewujudkan usaha yang keberlanjutan dilakukan melalui beberapa hal sebagai berikut :
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>
							Pembuatan aplikasi untuk kemudahan penagihan piutang premi dan penetapan KPI terkait penagihan piutang premi.
						</p>
					</li>
					<li>
						<p>
							Reorganisasi untuk refocusing penanganan bisnis sesuai segmen bisnis pada pilar strategis perusahaan.
						</p>
					</li>
					<li>
						<p>
							Melakukan pelatihan secara berkala dengan fokus pada kompetensi yang akan ditingkatkan dan melakukan standarisasi kompetensi dengan Perusahaan induk.
						</p>
					</li>
					<li>
						<p>
							Melakukan diversifikasi investasi dengan mempertimbangkan potensi risiko dan tetap sesuai dengan ketentuan regulator.
						</p>
					</li>
					<li>
						<p>
							Perusahaan selalu berupaya memberikan layanan terbaik atas produk - produk asuransi unggulan kepada konsumen.
						</p>
					</li>
					<li>
						<p>
							Perusahaan memberlakukan kesetaraan kesempatan bekerja kepada seluruh pekerja, memperlakukan secara adil atau non diskriminatif, memberikan kesempatan yang sama untuk mengembangkan kompetensi, dan menyediakan lingkungan kerja yang sehat dan aman serta tidak adanya tenaga kerja paksa dan tenaga kerja anak.
						</p>
					</li>
					<li>
						<p>
							Perusahaan memberikan kelayakan upah pada setiap pekerja sesuai dengan perhitungan remunerasi sesuai dengan upah minimum regional.
						</p>
					</li>
					<li>
						<p>
							Perusahaan memberikan dan menyediakan lingkungan kerja yang aman, sehat, dan layak kepada setiap pekerjanya untuk memberikan rasa nyaman saat bekerja.
						</p>
					</li>
					<li>
						<p>
							Perusahaan memberikan kesempatan yang sama bagi setiap pekerja untuk membagun atau meningkatkan kompetensi dengan mengikuti berbagai macam pelatihan, workshop, dan seminar baik di dalam maupun di luar lingkungan perusahaan.
						</p>
					</li>
					<li>
						<p>
							Perusahaan mengadakan kegiatan dalam rangka meningkatkan literasi dan inklusi keuangan secara rutin setiap tahunnya.
						</p>
					</li>
					<li>
						<p>
							Untuk melayani pengaduan masyarakat, Perusahaan menyediakan layanan contact center di nomor 14081 untuk memberikan solusi secepatnya terhadap pengaduan masyarakat.
						</p>
					</li>
					<li>
						<p>
							Dalam bentuk pelibatan masyarakat sekitar, Perusahaan menyelenggarakan berbagai program CSR .
						</p>
					</li>
				</ol>
			</div>
		</div>
	</div>
	<div class="DivContentDescription" *ngIf="_numberSocialValueSubmainActive === _enumSocialValueSubmain.WhistleblowingSystem">
		<h1>Mekanisme Whistleblowing</h1>
		<div id="divBodyTabAbout" class="DivBodyContainer">
			<div class="DivContentMain">
				<p>
					Dalam rangka mewujudkan lingkungan Perusahaan yang bersih, transparan, terpercaya dan bertanggung jawab, Perusahaan perlu membangun sistem pengaduan yang tanggap, transparan, aman dan bertanggung jawab. Oleh karena itu, BRI Insurance memberikan kemudahan dan media pelaporan apabila terjadi pelanggaran, BRI Insurance telah membentuk mekanisme Pelaporan Penyimpangan atau Pelanggaran <em>(Whistleblowing System)</em> sebagai acuan resmi sistem pelaporan pelanggaran. Untuk itu Perusahaan memberikan sarana bagi para pelapor, baik pihak internal maupun eksternal Perusahaan untuk menyampaikan dugaan dan/atau kejadian penyimpangan atau pelanggaran termasuk tindakan kecurangan <em>(<em>fraud</em>)</em>.
				</p>
				<ol class="OrderedNumber">
					<li>
						<p>Pengaduan hendaknya dilengkapi dengan dokumen pendukung, sebagai bukti adanya penyimpangan atau pelanggaran.</p>
					</li>
					<li>
						<p>
							Kerahasiaan identitas pelapor dijamin Perusahaan dan Perusahaan akan memberikan sanksi tegas bagi yang melanggar prinsip kerahasiaan tersebut.
						</p>
					</li>
					<li>
						<p>
							Setiap pelapor berhak untuk mendapatkan hak perlindungan dari Perusahaan.
						</p>
					</li>
					<li>
						<p>
							BRI Insurance memiliki Tim Adhoc WBS untuk Penyelesaian Pelanggaran Disiplin yang akan bertugas untuk menindaklanjuti laporan yang diterima.
						</p>
					</li>
					<li>
						<p>
							Pelapor dapat memberikan informasi/laporan pelanggaran secara anonim (tanpa nama).
						</p>
					</li>
				</ol>

				<p class="ParagraphBold">Pelaporan Penyimpangan atau Pelanggaran Karyawan dapat disampaikan melalui: </p>
				<ol class="OrderedNumber">
					<li>
						<p>Email (wbs@work.brins.co.id) </p>
					</li>
					<li>
						<p>SMS atau Whatsapp ke nomor 0811 1950 8899</p>
					</li>
				</ol>

				<!-- <div id="divBodyTabAbout" class="DivBodyContainer">
					<div class="DivContentMain" id="WhistleblowingSystemForm">
						<form>
							<div class="DivForm">
								<fieldset>
									<div class=DivFormHorizontalContainer>
										<label for="selectWhistleblowingIdentity">Apakah Anda bersedia memberikan identitas diri Anda?*</label>
										<select name="modelWhistleblowingIdentity" id="selectWhistleblowingIdentity">
												<option>Pilih jawaban</option>
												<option value="true"> Ya </option>
												<option value="false"> Tidak </option>
										</select>

										<label for="inputWhistleblowingReportedName">Nama Anda*</label>
										<input type="text" id="inputWhistleblowingReportedName" placeholder="Nama pelapor">

										<label for="inputWhistleblowingReportedPosition">Nomor Anda*</label>
										<input type="text" id="inputWhistleblowingReportedNumber" placeholder="Nomor pelapor">

										<label for="inputWhistleblowingReportedEmail">Email Anda*</label>
										<input type="text" id="inputWhistleblowingReportedEmail" placeholder="Email pelapor">

										<label for="selectWhistleblowingReportCriteria">Kriteria Laporan*</label>
										<select name="modelWhistleblowingReportCriteria" id="selectWhistleblowingReportCriteria">
												<option>Pilih kriteria</option>
												<option value="0"> Pengaduan </option>
												<option value="1"> Lain-lain </option>
										</select>

										<label for="inputWhistleblowingNameReported">Nama pihak yang dilaporkan*</label>
										<input type="text" id="inputWhistleblowingNameReported" placeholder="Nama pihak yang dilaporkan">

										<label for="inputWhistleblowingPositionReported">Jabatan pihak yang dilaporkan*</label>
										<input type="text" id="inputWhistleblowingPositionReported" placeholder="Jabatan pihak yang dilaporkan">

										<label for="selectWhistleblowingPositionReported">Jabatan pihak yang dilaporkan*</label>
										<select name="modelWhistleblowingReportCriteria" id="selectWhistleblowingReportCriteria">
												<option>Pilih kriteria</option>
												<option value="0"> Pengaduan </option>
												<option value="1"> Lain-lain </option>
										</select>

										<label for="inputWhistleblowingIncidentLocation">Lokasi Kejadian*</label>
										<input type="text" id="inputWhistleblowingIncidentLocation" placeholder="Lokasi kejadian">

										<label for="inputWhistleblowingIncidentTime">Waktu Kejadian*</label>
										<input type="text" id="inputWhistleblowingIncidentTime" placeholder="Waktu kejadian">

										<label for="inputWhistleblowingChronology">Kronologi Kejadian*</label>
										<textarea type="text" id="inputWhistleblowingChronology"></textarea>

									</div>
									<label for="inputWhistleblowingAttachment">Lampiran*</label>
									<input type="button" id="inputWhistleblowingAttachment" class="inputAttachment" name="buttonSubmit" value="Unggah dokumen" (click)="fileAttachment.click();">
									<input #fileAttachment type="file" (click)="listenFileUpload($event)">

									<label>{{ _modelClaimProposalGallery.Name | convertEmptyToDash  }}</label>

									<input type="button" class="inputButton" name="buttonSubmit" value="Kirim Pesan">
								</fieldset>
							</div>
						</form>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>

<div id="divBodySocialValueCSR" class="DivBodySiteContainer">
	<div class="DivContentDescription">
		<h1>Tanggung Jawab Sosial Perusahaan</h1>
		<div class="DivContentMain">
			<ng-container  *ngFor="let modelContentBRINSDetail of paginateSocialValueCSR(_numberIndexSocialValueCSR); let i = index">
				<div class="DivContentBoxDetail" [ngClass]="{'DivContentSectionActive': _numberIndexSocialValueCSR === i}">
					<img [src]="getImageFromBroadcastURL(modelContentBRINSDetail.Path)">
					<div class="DivContentBoxDescription">
						<p> {{ modelContentBRINSDetail.Description }} </p>
					</div>
				</div>
			</ng-container>
		</div>

		<div style="text-align:center">
			<ng-container *ngFor="let numberPaginate of _arrayPageSizeSocialValueCSR; let i=index">
				<span class="SpanContentPage SpanContentPageDark"  (click)="showPaginateSocialValueCSR(numberPaginate)" [ngClass]="{'active': _numberIndexSocialValueCSR === i}"></span>
			</ng-container>
		</div>
	</div>

</div>

<div id="divBodyDashboardBRINSUpdate" class="DivBodySiteContainer">
	<div id="divSocialValueBRINSUpdate" class="DivContentCorporate">
		<div class="DivContentCorporateTitle">
			<h1>Kegiatan</h1>
		</div>
		<div class="DivContentImageCorousel">
			<section id="sectionImageCorousel">
				<input *ngFor="let modelContent of _arrayModelContent5; let _numberIndexBRINSUpdate = index " type="radio" name="slider" id="s{{_numberIndexBRINSUpdate}}" [checked]="_numberIndexBRINSUpdate === 0">
				<label *ngFor="let modelContent of _arrayModelContent5; let _numberIndexBRINSUpdate = index " for="s{{_numberIndexBRINSUpdate}}" id="slide{{_numberIndexBRINSUpdate}}">
					<img [src]="getImageFromBroadcastURL(modelContent.Path)" alt="" (click)="setIndexContentBRINSWebsite(_numberIndexBRINSUpdate)">
				</label>
			</section>
		</div>
		<div class="DivContentDescription">
			<p class="ParagraphCenter">
				{{ _arrayModelContent5[_numberIndexBRINSUpdate].Description }}
			</p>
		</div>
	</div>
</div>

<app-footer></app-footer>