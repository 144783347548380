
<div id="divMainLanding">
    <div id="divBodyDashboard1" class="DivBodyGeneral" [ngStyle]="getImagePathHome(_numberIndexDashboard)">

        <div class="DivContentDashboard">
            <div class="DivContentBackground"></div>
            <div class="DivContentSection" *ngFor="let modelContent of _arrayModelHero; let i=index  " [ngClass]="{'DivContentSectionActive': _numberIndexDashboard === i}">
                <div class="DivContentTitle">
                    <h3>{{ modelContent.Title }}</h3>
                </div>
                <input type="button" class="ButtonResponsive" value="Selengkapnya →" (click)="goToAbout()">
                <div *ngIf="modelContent.Detail" class="DivContentButtonDetail">
                    <div (click)="this.goToAbout()" class="ButtonForward">
                        <span class="ButtonContent"></span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="DivPage">
            <ng-container *ngFor="let modelContent of _arrayModelHero; let i=index">
                <span class="SpanContentPage SpanContentPageSoft"  (click)="showContent1(modelContent.ID!)" [ngClass]="{'active': _numberIndexDashboard === i}"></span>
            </ng-container>
        </div>

    </div>

    <div id="divBodyDashboardBussinesPillars" class="DivBodyGeneral ">
        <div class="DivContentLayoutContent">
            <div class="DivContentCorporate">
                <div class="DivContentCorporateTitle">
                    <h1>Pilar Bisnis</h1>
                </div>
                <div class="DivContentCorporatePage">
                    <div class="DivContentCorporatePageImage">
                        <div class="DivClipWrap" id="divClipWrap">
                            <img class="ImageClipSVG ImageCurrent" [src]=this._arrayModelPillars[0].Path>
                            <img class="ImageClipSVG" [src]=this._arrayModelPillars[1].Path>
                            <img class="ImageClipSVG" [src]=this._arrayModelPillars[2].Path>
                            <img class="ImageClipSVG" [src]=this._arrayModelPillars[3].Path>
                        </div>

                        <h1 [ngClass]="getThemeColorPillar(this._arrayModelPillars[_numberIndexCorporate].ID!)">{{ this._arrayModelPillars[_numberIndexCorporate].Name }}</h1>
                    </div>
                </div>
                <div class="DivContentCorporateDescription">
                    <p>
                        {{ this._arrayModelPillars[_numberIndexCorporate].Description }}
                    </p>
                </div>
            </div>

            <input type="button" class="ButtonResponsive" value="Selengkapnya →" (click)="goToPillar(this._arrayModelPillars[_numberIndexCorporate].Path2!)">
			<div class="DivContentButtonDetail">
				<div (click)="goToPillar(this._arrayModelPillars[_numberIndexCorporate].Path2!)" class="ButtonForward">
					<span class="ButtonContent"></span>
					<svg width="13px" height="10px" viewBox="0 0 13 10">
						<path d="M1,5 L11,5"></path>
						<polyline points="8 1 12 5 8 9"></polyline>
					</svg>
				</div>
			</div>
        </div>

        <div class="DivContentLayoutSide">
            <ng-container *ngFor="let modelContent of _arrayModelPillars; let i=index">
                <span class="SpanContentPage SpanContentPageDark"  (click)="showContent2(modelContent.ID!)" [ngClass]="{'active': _numberIndexCorporate === i}"></span>
            </ng-container>
        </div>
    </div>

    <div id="divBodyDashboardAboutBRINS" class="DivBodyGeneral ">
        <div class="DivContent">
            <div class="DivContentTitle">
                <h1>TENTANG BRINS</h1>
                <hr>
            </div>
            <div class="DivContentDescription">
                <p class="PCorporateDescription">
                    {{ _stringAboutBRINSDescription }}
                </p>
            </div>
            <input type="button" class="ButtonResponsive2" value="Selengkapnya →" (click)="goToAbout()">
            <div class="DivContentButtonDetail">
                <a (click)="this.goToAbout()" class="ButtonForward ButtonForwardLight">
                    <span>Selengkapnya</span>
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                </a>
            </div>
        </div>
    </div>

    <div id="divBodyDashboardAward" class="DivBodyGeneral">
		<div id="divMainAwards">
			<div class="DivContentAwardsTitle">
				<h1>Penghargaan</h1>
			</div>
			<div class="DivContentAwardsContent">
				<hr [ngStyle]="{'width': _arrayModelAwards.length*375+'px'}">
				<div class="DivVerticalLine" [ngStyle]="{'width': _arrayModelAwards.length*300+'px'}"></div>
				<ng-container *ngFor="let modelAward of _arrayModelAwards;">
					<div class="DivContentCalenderDetail">
						<div class="DivContentCalenderDate">
							<p>{{ modelAward.NowMonth }}</p>
							<h3>{{ modelAward.NowDate }}</h3>
							<p>{{ modelAward.NowYear }}</p>
						</div>
						<div class="DivContentCalenderDescription">
							<div class="DivContentCalenderTitle">
								<p>{{ modelAward.Description }}</p>
							</div>
							<div class="DivContentCalenderImage">
								<img *ngIf="modelAward.Path" [src]="modelAward.Path">
								<img *ngIf="modelAward.Path2" [src]="modelAward.Path2">
								<img *ngIf="modelAward.Path3" [src]="modelAward.Path3">
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="DivContentAwardsDescription" >
				<p>Penghargaan yang kami raih dari tahun ke tahun menjadi semangat kami untuk terus menghasilkan kinerja terbaik  dan inovasi terdepan kepada seluruh <em>stakeholders</em>.</p>
			</div>
		</div>
    </div>

    <div id="divBodyDashboardBRINSUpdate" class="DivBodyGeneral">
        <div class="DivContentCorporateSlider">
            <div class="DivContentCorporateTitle">
                <div class="DivContentHeader">
                    <h1>BRINS Update</h1>
                    <div class="DivContentTextDetail">
                        <p>Dapatkan berita terbaru tentang kami.</p>
                    </div>
                </div>
                <input type="button" class="ButtonResponsive" value="Selengkapnya →" (click)="goToBRINSUpdate()">
                <div class="DivContentButtonDetail">
                    <div href="https://www.brins.co.id" class="ButtonForward" (click)="this.goToBRINSUpdate();">
                        <span class="ButtonContent"></span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="DivContentImageCorousel">
                <section id="sectionImageCorousel">
                    <input *ngFor="let modelBrinsUpdate of _arrayModelBrinsUpdate; let _numberIndexBRINSUpdate = index " type="radio" name="slider" id="s{{_numberIndexBRINSUpdate}}" [checked]="_numberIndexBRINSUpdate === 0">
                    <label *ngFor="let modelBrinsUpdate of _arrayModelBrinsUpdate; let _numberIndexBRINSUpdate = index " for="s{{_numberIndexBRINSUpdate}}" id="slide{{_numberIndexBRINSUpdate}}">
                        <img [src]="getImageFromBroadcastURLForBrinsUpdate(modelBrinsUpdate)" alt="" (click)="setIndexContentBRINSWebsite(_numberIndexBRINSUpdate)">
                    </label>
                </section>
            </div>
            <div class="DivContentDescription">
                <h1 (click)="goToLink(_modelBrinsUpdateCarousel.Token!)">{{ _modelBrinsUpdateCarousel.Title }}</h1>
                <div class="DivContentBrinsUpdate" [innerHTML]="_modelBrinsUpdateCarousel.Content">

                </div>
                <p class="ParagraphCenter" id="PDateUpdate"><span>{{ _modelBrinsUpdateCarousel.PublishedOn | convertToDateShort }}</span></p>
            </div>
        </div>
    </div>

    <div id="divBodyDashboardPartners" class="DivBodyGeneral">
        <div class="DivContentPartners">
            <div class="DivContent2Column">
                <div class="DivContent">
                    <h2>Mitra</h2>
                    <p>Berjalan berdampingan bersama mitra kami untuk memberikan beragam perlindungan asuransi yang inovatif, aksesibel dan terpercaya di seluruh Indonesia.</p>
                    <img src="../../assets/icons/sakina/icon_partners.svg" alt="Icons partners" id="imageIconPartners">
                    <input type="button" class="ButtonResponsive2" value="Selengkapnya →" (click)="goToPartners()">
                    <div class="DivContentButtonDetail">
                        <a (click)="this.goToPartners()" class="ButtonForward ButtonForwardLight">
                            <span>Lihat Semua</span>
                            <svg width="13px" height="10px" viewBox="0 0 13 10">
                                <path d="M1,5 L11,5"></path>
                                <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="DivContent">
                    <div class="DivContentShapeDescription">
                        <img src="../../assets/shapes/sakina/shape_hexagonal.svg" class="ImageSpin">
                        <img id="logosPartners" [src]="_arrayModelPartners[_numberIndexAwards].Path">
                    </div>
                </div>
            </div>
        </div>
        <div class="DivContent" id="divContentBRIGroup">
            <h1>BRI Group</h1>
            <div class="DivContentImageGallery">
                <div class="DivButtonNavigate">
                    <a class="ButtonPreviousGallery" (click)="getPreviousGallery()"><img src="../../assets/icons/sakina/icon_prev.svg"></a>
                </div>
                <section id="sectionImageGallery">
                    <label for="inputImageGallery{{_numberIndexBRIGroupPrevious}}" id="labelImageGallery{{_numberIndexBRIGroupPrevious}}" class="labelImagePrevious" (click)="goToPillar(_arrayModelBRIGroup[_numberIndexBRIGroupPrevious].Path2!);">
                        <img [src]="_arrayModelBRIGroup[_numberIndexBRIGroupPrevious].Path" alt="">
                    </label>

                    <label for="inputImageGallery{{_numberIndexBRIGroup}}" id="labelImageGallery{{_numberIndexBRIGroup}}" class="labelImageActive" (click)="goToPillar(_arrayModelBRIGroup[_numberIndexBRIGroup].Path2!);">
                        <img [src]="_arrayModelBRIGroup[_numberIndexBRIGroup].Path" alt="">
                    </label>

                    <label for="inputImageGallery{{_numberIndexBRIGroupNext}}" id="labelImageGallery{{_numberIndexBRIGroupNext}}" class="labelImageNext" (click)="goToPillar(_arrayModelBRIGroup[_numberIndexBRIGroupNext].Path2!);">
                        <img [src]="_arrayModelBRIGroup[_numberIndexBRIGroupNext].Path" alt="">
                    </label>
                </section>
                <div class="DivButtonNavigate">
                    <a class="ButtonNextGallery" (click)="getNextGallery()"><img src="../../assets/icons/sakina/icon_next.svg"></a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>