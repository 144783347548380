<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>Nilai</h1><h1> Ekonomi</h1>
			</div>
			<div class="DivContentDescription">
				<p>Komitmen BRINS menjunjung tinggi nilai ekonomi yang berkelanjutan dan menciptakan nilai bagi para <i>stakeholders</i> sebagai upaya mendukung inklusi keuangan dan  penggerak perekonomian nasional.</p>
			</div>
			<div class="DivContentSearch">
				<img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
				<div class="DivContentSearchDetailFilter">
					<input type="text" [(ngModel)]="_stringInputValue" (keyup)="searchByValue()" placeholder="Cari Laporan Keuangan" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)">
					<div class="DivContentSearchResult" *ngIf="this._modelContentTemporary.length !== 0">
						<ul>
							<li *ngFor="let modelContentTemporary of this._modelContentTemporary" (click)="downloadFile(modelContentTemporary.Path!)">{{ modelContentTemporary.Name }} {{ modelContentTemporary.Description}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
				<img [src]="getImageFromBroadcastURL('../../Website/assets/background/sakina/brins/economicvalue/header_economic.jpg')">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divBodyTabAbout" class="DivBodyContainer">
	<h1>Laporan Keuangan Tahunan</h1>
    <!-- <div class="DivContentMain">
        <div class="DivContentTabAbout">
            <button (click)="goToAnnualFinancial(_enumEconomicValueType.Konvensional)" [ngClass]="_numberAnnualFinancialValueTypeActive ===  _enumEconomicValueType.Konvensional ? 'ButtonActive' : 'ButtonInActive'"> Konvensional - Syariah </button>
            <button (click)="goToAnnualFinancial(_enumEconomicValueType.Syariah)" [ngClass]="_numberAnnualFinancialValueTypeActive ===  _enumEconomicValueType.Syariah ? 'ButtonActive' : 'ButtonInActive'"> Syariah </button>
		</div>
    </div> -->
	<ng-container *ngIf="_numberAnnualFinancialValueTypeActive ===  _enumEconomicValueType.Konvensional" >
		<div class="DivContentDownload">
			<div class="DivContentDownloadMedium" *ngFor="let modelAnnualFinancial of this.paginateAnnualFinancialKonvensionalModel(_numberIndexAnnualKonvensionalFinancial); trackBy: trackAnnualFinancial">
				<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
				<div class="DivContentDownloadDescription">
					<label for="">{{ modelAnnualFinancial.Name }}</label>
					<label>{{ modelAnnualFinancial.Description }}</label>
					<a (click)="downloadFile(modelAnnualFinancial.Path!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
				</div>
			</div>
		</div>

		<div style="text-align:center">
			<ng-container *ngFor="let numberPaginate of _arrayPageSizeAnnualKonvensionalFinancial; let i=index">
				<span class="SpanContentPage SpanContentPageDark" (click)="showPaginateAnnualKonvensionalFinancial(numberPaginate)" [ngClass]="{'active': _numberIndexAnnualKonvensionalFinancial === i}"></span>
			</ng-container>
		</div>
	</ng-container>

</div>

<div id="divBodyTabAbout" class="DivBodyContainer">
	<h1>Laporan Keuangan Bulanan</h1>
    <div class="DivContentMain">
        <div class="DivContentTabAbout">
            <button (click)="goToQuarterlyReports(_enumEconomicValueType.Konvensional)" [ngClass]="_numberQuarterlyReportsValueTypeActive ===  _enumEconomicValueType.Konvensional ? 'ButtonActive' : 'ButtonInActive'"> Konvensional </button>
            <button (click)="goToQuarterlyReports(_enumEconomicValueType.Syariah)" [ngClass]="_numberQuarterlyReportsValueTypeActive ===  _enumEconomicValueType.Syariah ? 'ButtonActive' : 'ButtonInActive'"> Syariah </button>
		</div>
    </div>

	<ng-container *ngIf="_numberQuarterlyReportsValueTypeActive ===  _enumEconomicValueType.Konvensional">
		<div class="DivContentDownload">
			<div class="DivContentDownloadMedium" *ngFor="let modelQuarterlyReports of this.paginateQuarterlyReportsModel(_numberIndexQuarterlyReports); trackby: trackQuarterlyReports">
				<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
				<div class="DivContentDownloadDescription">
					<label for="">{{ modelQuarterlyReports.Name }}</label>
					<a (click)="downloadFile(modelQuarterlyReports.Path!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
				</div>
			</div>
		</div>
		<div style="text-align:center">
			<ng-container *ngFor="let numberPaginate of _arrayPageSizeQuarterlyReports; let i=index">
				<span class="SpanContentPage SpanContentPageDark" (click)="showPaginateQuarterlyReports(numberPaginate)" [ngClass]="{'active': _numberIndexQuarterlyReports === i}"></span>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="_numberQuarterlyReportsValueTypeActive ===  _enumEconomicValueType.Syariah">
		<div class="DivContentDownload">
			<div class="DivContentDownloadMedium" *ngFor="let modelQuarterlyReportsSyariah of this.paginateQuarterlyReportsSyariahModel(_numberIndexQuarterlyReportsSyariah); trackby: trackQuarterlyReports">
				<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
				<div class="DivContentDownloadDescription">
					<label for="">{{ modelQuarterlyReportsSyariah.Name }}</label>
					<a (click)="downloadFile(modelQuarterlyReportsSyariah.Path!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
				</div>
			</div>
		</div>
		<div style="text-align:center">
			<ng-container *ngFor="let numberPaginate of _arrayPageSizeQuarterlyReportsSyariah; let i=index">
				<span class="SpanContentPage SpanContentPageDark" (click)="showPaginateQuarterlyReportsSyariah(numberPaginate)" [ngClass]="{'active': _numberIndexQuarterlyReportsSyariah === i}"></span>
			</ng-container>
		</div>
	</ng-container>
</div>

<app-footer></app-footer>