<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>Profil</h1>
			</div>
			<div class="DivContentDescription">
				<p>Lengkapi data diri Anda disini dan dapatkan perlindungan Anda dengan cepat dan mudah. Profile Anda merupakan satu langkah awal untuk perlindungan diri Anda, orang-orang tersayang dan aset Anda.</p>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
                <img [src]="getImageFromBroadcastURL('../../Website/assets/background/sakina/brins/updateprofile/header_profile.jpg')">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divBodyEditProfile" class="DivBodyContainer">
	<div class="DivEditProfileFormContainer FlexGrow1 FlexShrink1">
		<form>
			<div class="DivForm">
				<div class="DivFormHeader">
					<h4>Personal</h4>
					<span>Satu langkah menuju perlindungan</span>
				</div>

				<div class="DivFormPhotoProfile">
					<div class="DivFormColumn">
						<label>Unggah Foto</label>
					</div>
					<div class="DivFormColumn">
						<figure>
							<div class="DivFormFigure">
								<ng-container *ngIf="_modelUserPublic.listModelUserGallery?.length === 0 || _modelUserPublic.listModelUserGallery === undefined">
									<img *ngIf="_modelUserPublicResponse.PhotoURL" [src]="_modelUserPublicResponse.PhotoURL" id="imageProfilePicture">
									<span *ngIf="!_modelUserPublicResponse.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
								</ng-container>

								<ng-container *ngIf="_modelUserPublic.listModelUserGallery?.length !== 0">
									<ng-container *ngFor="let modelUserPublic of _modelUserPublic.listModelUserGallery">
										<ng-container *ngIf="modelUserPublic.Data === undefined || modelUserPublic.Data === ''">
											<img [src]="getImage(modelUserPublic.URL)"id="imageProfilePicture">
										</ng-container>

										<ng-container *ngIf="modelUserPublic.Data !== undefined && modelUserPublic.Data !== ''">
											<img *ngIf="modelUserPublic.State === 0" [src]="modelUserPublic.Data" id="imageProfilePicture">
										</ng-container>
									</ng-container>
								</ng-container>
							</div>
						</figure>
					</div>
					<div class="DivFormColumn" id="divFormColumnButton">
						<input #fileAttachment type="file" (change)="listenFileUpload($event)">
						<label for="fileAttachment" class="LabelAttachment" id="labelAttachment">
							<input type="button" class="ButtonAttachment" (click)="fileAttachment.click();" value="Ganti Foto">
						</label>
					</div>
				</div>

				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="textNamaDepan">Nama Depan</label>
								<div class="DivInputHorizontalContainer" >
									<input type="text" id="textNamaDepan" name="modelUserFirstName" placeholder="Masukkan nama depan" [(ngModel)]="_modelUserPublic.FirstName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textNamaTengah">Nama Tengah</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textNamaTengah" name="modelUserMiddleName" placeholder="Masukkan nama tengah" [(ngModel)]="_modelUserPublic.MiddleName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textNamaBelakang">Nama Belakang</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textNamaBelakang" name="modelUserLastName" placeholder="Masukkan nama belakang" [(ngModel)]="_modelUserPublic.LastName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="radioJenisKelamin">Jenis Kelamin</label>
								<div class="DivRadioButtonContainer">
									<label class="LabelRadioButtonContainer LabelRadioButton" for="radioJenisKelaminLakilaki" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.Gender === this._enumGenderType.Male }" (click)="setGender(this._enumGenderType.Male)">
										<input type="radio" name="modelUserPublicGender" id="radioJenisKelaminLakilaki" [checked]="_modelUserPublic.Gender === this._enumGenderType.Male">Laki-laki
									</label>
	
									<label class="LabelRadioButtonContainer LabelRadioButton" for="radioJenisKelaminPerempuan" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.Gender === this._enumGenderType.Female }" (click)="setGender(this._enumGenderType.Female)">
										<input type="radio" name="modelUserPublicGender" id="radioJenisKelaminPerempuan" [checked]="_modelUserPublic.Gender === this._enumGenderType.Female">Perempuan
									</label>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textTempatLahir">Tempat Lahir</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textTempatLahir" name="modelUserBirthPlace" placeholder="Masukkan tempat lahir" [(ngModel)]="_modelUserPublic.BirthPlace" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivLocation"></div>
								</div>
							</div>
							
							<div class="DivDateTimePicker">
								<label for="selectModelProductOtoVehicleManufactureYearCode">Tanggal Lahir</label>
								<mat-form-field appearance="fill" class="Margin0"  (click)="dpReporterDate.open()">
									<mat-label>Pilih tanggal</mat-label>
									<input matInput [matDatepicker]="dpReporterDate" [value]="_modelUserPublic!.BirthDate" [max]="this._dateNow" (dateChange)="getBirthDate($event);" readonly>
									<mat-datepicker-toggle matSuffix [for]="dpReporterDate"></mat-datepicker-toggle>
									<mat-datepicker #dpReporterDate startView="month" panelClass="month-picker"></mat-datepicker>
								</mat-form-field>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="radioCitizenship">Kewarganegaraan</label>
								<div class="DivRadioButtonContainer">
									<label class="LabelRadioButtonContainer LabelRadioButton" for="radioCitizenshipWNI" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.CitizenshipStatus === this._enumCitizenship.Indonesian }" (click)="setCitizenship(this._enumCitizenship.Indonesian)">
										<input type="radio" name="modelUserPublicCitizensipStatus" id="radioCitizenshipWNI" [checked]="_modelUserPublic.CitizenshipStatus === this._enumCitizenship.Indonesian">WNI
									</label>
		
									<label class="LabelRadioButtonContainer LabelRadioButton" for="radioCitizenshipForeign" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.CitizenshipStatus === this._enumCitizenship.Foreign }" (click)="setCitizenship(this._enumCitizenship.Foreign)">
										<input type="radio" name="modelUserPublicCitizensipStatus" id="radioCitizenshipForeign" [checked]="_modelUserPublic.CitizenshipStatus === this._enumCitizenship.Foreign">WNA
									</label>
								</div>
							</div>
							
							<div class="DivFormHorizontal MarginBottom0">
								<label for="textKtp">KTP</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textKtp" name="modelUserIdentificationNumber" placeholder="Masukkan 16 digit nomor ktp" [(ngModel)]="_modelUserPublic.IdentificationNumber" maxlength="16" (keypress)="this._functionRegex.regexNumber($event)">
									<div class="DivKtp"></div>
								</div>
							</div>
						</div>
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="selectMaritalStatus">Status Perkawinan</label>
								<div class="DivFormSingleDropdown" id="selectMaritalStatus">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="false"
									[hideSelected]="true"
									[(ngModel)]=_modelUserPublic.MaritalStatus
									[ngModelOptions]="{standalone: true}"
									>
									<ng-option class="custom" [value]="modelMaritalStatus" *ngFor="let modelMaritalStatus of _arrayEnumMaritalStatus; let numberIndex = index">
										{{ getMaritalStatus(modelMaritalStatus) }}
									</ng-option>
								</ng-select>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="selectSourceOfFounds">Sumber Dana</label>
								<div class="DivFormSingleDropdown" id="selectSourceOfFounds">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelSourceOfIncome
									[ngModelOptions]="{standalone: true}"
									(change)="setSourceOfIncome(_modelSourceOfIncome)"
									[items]="_arrayModelSourceOfIncome"
									bindLabel="Name"
									>
									<ng-option class="custom" [value]="modelSourceOfIncome" *ngFor="let modelSourceOfIncome of _arrayModelSourceOfIncome; let numberIndex = index">
                                        {{ modelSourceOfIncome.Name }}
                                    </ng-option>
								</ng-select>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="selectOccupation">Pekerjaan</label>
								<div class="DivFormSingleDropdown" id="selectOccupation">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelOccupation
									[ngModelOptions]="{standalone: true}"
									(change)="setOccupation(_modelOccupation)"
									[items]="_arrayModelOccupation"
									bindLabel="Name"
									>
									<ng-option class="custom" [value]="modelOccupation" *ngFor="let modelOccupation of _arrayModelOccupation; let numberIndex = index">
                                        {{ modelOccupation.Name }}
                                    </ng-option>
								</ng-select>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="selectAverageIncomePerYear">Penghasilan Rata-Rata per Tahun</label>
								<div class="DivFormSingleDropdown" id="selectAverageIncomePerYear">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelAverageIncomePerYear
									[ngModelOptions]="{standalone: true}"
									(change)="setAverageIncomePerYear(_modelAverageIncomePerYear)"
									[items]="_arrayModelAverageIncomePerYear"
									bindLabel="Name"
									>
									<ng-option class="custom" [value]="modelAverageIncomePerYear" *ngFor="let modelAverageIncomePerYear of _arrayModelAverageIncomePerYear; let numberIndex = index">
                                        {{ modelAverageIncomePerYear.Name }}
                                    </ng-option>
								</ng-select>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textMotherMaidenName">Nama Gadis Ibu Kandung</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textMotherMaidenName" name="modelUserMotherMaidenName" placeholder="Masukkan nama gadis ibu kandung" [(ngModel)]="_modelUserPublic.MothersMaidenName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivLocation"></div>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textBeneficiariesName">Ahli Waris</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textBeneficiariesName" name="modelUserBeneficiarieName" placeholder="Masukkan nama ahli waris" [(ngModel)]="_modelUserPublic.BeneficiaryName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivLocation"></div>
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="selectCabang">Cabang BRINS Terdekat</label>
								<div class="DivFormSingleDropdown" id="selectCabang">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelBranchResponse
									[ngModelOptions]="{standalone: true}"
									(ngModelChange)="setBranch(_modelBranchResponse)"
									[items]="_arrayModelBranch"
									bindLabel="Name"
									>
									<ng-option class="custom" [value]="modelBranch" *ngFor="let modelBranch of _arrayModelBranch; let numberIndex = index">
										{{ modelBranch.Name }}
									</ng-option>
								</ng-select>
								</div>
							</div>
							
							<div class="DivFormHorizontal MarginBottom0">
								<label for="selectInsurancePurpose">Maksud/Tujuan Hubungan Usaha</label>
								<div class="DivFormSingleDropdown" id="selectInsurancePurpose">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelInsurancePurpose
									[ngModelOptions]="{standalone: true}"
									(ngModelChange)="setInsurancePurpose(_modelInsurancePurpose)"
									[items]="_arrayModelInsurancePurpose"
									bindLabel="Name"
									>
									<ng-option class="custom" [value]="modelInsuracePurpose" *ngFor="let modelInsuracePurpose of _arrayModelInsurancePurpose; let numberIndex = index">
										{{ modelInsuracePurpose.Name }}
									</ng-option>
								</ng-select>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivForm">
				<div class="DivFormHeader">
					<h4>Kontak</h4>
					<span>Satu langkah menuju perlindungan</span>
				</div>

				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal MarginBottom0">
								<label for="emailEmail">Email</label>
								<div class="DivInputHorizontalContainer">
									<input type="email" id="emailEmail" name="modelUserEmail" placeholder="Masukkan email Anda" [(ngModel)]="_modelUserPublic.Email" disabled>
								</div>
							</div>
						</div>

						<div class="DivForm2Column">
							<label class="LabelBlank">&nbsp;</label>
							<div class="DivFormLabelHorizontal MarginBottom0">
								<label for="spanEmail">Anda dapat mengganti email
									<span id="spanEmail" (click)="goToEditEmail(_modelUserPublic.Token)">&nbsp;disini</span>
								</label>
							</div>
						</div>
					</div>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal MarginBottom0">
								<label for="textNomorHp">Nomor Hp</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textNomorHp" name="modelUserPhone" placeholder="Masukkan nomor hp"
									[(ngModel)]="_modelUserPublic.MobilePhone" disabled (keypress)="this._functionRegex.regexNumber($event)">
								</div>
							</div>
						</div>

						<div class="DivForm2Column">
							<label class="LabelBlank">&nbsp;</label>
							<div class="DivFormLabelHorizontal MarginBottom0">
								<label for="spanNoHp">Anda dapat mengganti nomor handphone
									<span id="spanNoHp" (click)="goToEditMobilePhone(_modelUserPublic.Token)">&nbsp;disini</span>
								</label>
							</div>
						</div>
					</div>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="textKodePos">Kode Pos</label>
								<div class="DivInputHorizontalContainer DivAlignCenterContainer">
									<input type="text" id="textKodePos" name="modelUserPostalCode" placeholder="Masukkan kode pos"
									[(ngModel)]="this._modelUserPublic!.PostalCode" (keyup)="selectPostalCode()" maxlength="5" (keypress)="this._functionRegex.regexNumber($event)">
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textProvinsi">Provinsi</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textProvinsi" name="modelUserProvince"
									[(ngModel)]="this._modelUserPublic!.ProvinceName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textKota">Kota</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textKota" name="modelUserCity" [(ngModel)]="this._modelUserPublic!.CityName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							
							<div class="DivFormHorizontal">
								<label for="textLokasi">Lokasi</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textLokasi" name="modelUserLocation" [(ngModel)]="this._modelUserPublic!.CountryName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							
						</div>
						<div class="DivForm2Column">
							<div class="DivFormHorizontal DivFormHorizontalAddress">
								<label for="textAlamat">Alamat</label>
								<div class="DivInputHorizontalContainer">
									<textarea id="textAlamat" name="modelUserAddress"  [(ngModel)]="this._modelUserPublic!.Address" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
								</div>
							</div>

							<div class="DivFormHorizontal DivFormHorizontalAddress">
								<label for="textWorkOfficeAddress">Alamat Tempat Kerja</label>
								<div class="DivInputHorizontalContainer">
									<textarea id="textWorkOfficeAddress" name="modelUserAddress"  [(ngModel)]="this._modelUserPublic.WorkplaceAddress" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
								</div>
							</div>

							<div class="DivFormHorizontal">
								<label for="textWorkOfficePhoneNumber">Nomor Telepon Tempat Kerja</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textWorkOfficePhoneNumber" name="modelUserWorkOfficePhoneNumber" placeholder="Masukkan nomor telepon tempat kerja"
									[(ngModel)]="_modelUserPublic.WorkplaceTelephoneNumber" (keypress)="this._functionRegex.regexNumber($event)">
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivForm">
				<div class="DivFormBubble MarginBottom15">
					<h6>Persetujuan penggunaan data nasabah.</h6>

					<div class="MarginBottom15">
						<span>{{_stringConstant.STRING_APPROVAL_USER_CUSTOMER_DATA_HEADER}}</span>
					</div>
					<div class="DivCheckBoxContainer MarginBottom15">
						<label class="LabelCheckboxContainer" for="checkboxApproveTheStatementFirst">
							<input type="checkbox" name="checkboxApproveTheStatementFirst" id="checkboxApproveTheStatementFirst" [(ngModel)]="_modelUserPublic.DataSharingConsent" (change)="setBooleanDataSharingConsent($event.target)">
							{{_stringConstant.STRING_APPROVAL_USER_CUSTOMER_DATA_FIRST}}
						</label>
					</div>
					<div class="DivCheckBoxContainer">
						<label class="LabelCheckboxContainer" for="checkboxApproveTheStatementSecond">
							<input type="checkbox" name="checkboxApproveTheStatementSecond" id="checkboxApproveTheStatementSecond" [(ngModel)]="_modelUserPublic.ProductPromotionConsent" (change)="setBooleanProductPromotionConsent($event.target)">
							{{_stringConstant.STRING_APPROVAL_USER_CUSTOMER_DATA_SECOND}}
						</label>
					</div>
				</div>

				<div class="DivFormBubble Margin0">
					<h6>Catatan</h6>
					<span>{{_stringConstant.STRING_APPROVAL_USER_CUSTOMER_DATA_NOTE}}</span>
				</div>
			</div>

			<div class="DivContentButtonDetail">
				<a class="ButtonForward" (click)="callUpdateCustomerProfile(this)">
					<span class="ButtonContent"></span>
					<svg width="13px" height="10px" viewBox="0 0 13 10">
						<path d="M1,5 L11,5"></path>
						<polyline points="8 1 12 5 8 9"></polyline>
					</svg>
				</a>
			</div>
		</form>
	</div>
</div>
<app-footer></app-footer>