/* eslint-disable max-len */

//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_ECONOMICVALUE_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { RegexFunction } from "src/app/functions/regex.function";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-economicvalue",
		templateUrl: "./economicvalue.component.html",
		styleUrls: ["./economicvalue.component.sass"],
	}
)
export class EconomicValueComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _enumStringConstant = StringConstant;
	public _enumEconomicValueType = ENUM_ECONOMICVALUE_TYPE;
	public _numberConstant = NumberConstant;
	public _stringInputValue: string;
	public _numberAnnualFinancialValueTypeActive: number;
	public _numberQuarterlyReportsValueTypeActive: number;

	public _arrayModelAnnualKonvensionalFinancial: Array<ContentModel>;
	public _arrayModelQuarterlyReports: Array<ContentModel>;
	public _arrayModelQuarterlyReportsSyariah: Array<ContentModel>;

	public _numberPageSizeAnnualKonvensionalFinancial: number;
	public _numberIndexAnnualKonvensionalFinancial: number;
	public _arrayPageSizeAnnualKonvensionalFinancial: Array<number>;

	public _numberPageSizeQuarterlyReports: number;
	public _numberIndexQuarterlyReports: number;
	public _arrayPageSizeQuarterlyReports: Array<number>;

	public _numberPageSizeQuarterlyReportsSyariah: number;
	public _numberIndexQuarterlyReportsSyariah: number;
	public _arrayPageSizeQuarterlyReportsSyariah: Array<number>;

	public _modelContentTemporary: Array<ContentModel>;
	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	public _functionRegex: RegexFunction;

	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._numberAnnualFinancialValueTypeActive = 0;
		this._numberQuarterlyReportsValueTypeActive = 0;
		this._stringInputValue = "";
		this._modelContentTemporary = [];
		this._functionRegex = new RegexFunction();

		this._arrayModelAnnualKonvensionalFinancial =
		[
			{
				Name: "BRINS AR - Annual Report",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2023 - Annual Report.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2023.pdf"
			},
			{
				Name: "Sustainability Report",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/SR_2023_PT_BRI_Asuransi_Indonesia.pdf"
			},
			{
				Name: "BRINS AR - Annual Report",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2022 - Annual Report.pdf"
			},
			{
				Name: "Sustainability Report",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/SR_2022_PT_BRI_Asuransi_Indonesia.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2022.pdf"
			},
			{
				Name: "BRINS AR - Annual Report",
				Description: "2021",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2021 - Annual Report.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2021",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2020-2021.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2020",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2019-2020.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2019",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2018-2019.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2018",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2017-2018.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2017",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2016-2017.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2016",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2015-2016.png"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2015",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2014-2015.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2014",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2013-2014.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2013",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2012-2013.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2012",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2011-2012.png"
			},
		];

		this._arrayModelQuarterlyReports =
		[
			{
				Name: "Laporan Publikasi Bulan Oktober 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Oktober 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan September 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Agustus 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juli 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juni 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Mei 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan April 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Maret 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Maret 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Februari 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Februari 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Januari 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Januari 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Desember 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Desember 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan November 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan November 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Oktober 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Oktober 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan September 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Agustus 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juli 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juni 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Mei 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan April 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2017 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2017 Konvensional.pdf"
			},
		];

		this._arrayModelQuarterlyReportsSyariah =
		[
			{
				Name: "Laporan Publikasi Bulan Oktober 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Oktober 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan September 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Agustus 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juli 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juni 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Mei 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan April 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Maret 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Maret 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Februari 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Februari 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Januari 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Januari 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Desember 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Desember 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan November 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan November 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Oktober 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Oktober 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan September 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Agustus 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juli 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juni 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Mei 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan April 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2017 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2017 Syariah.pdf"
			},
		];

		this._numberIndexAnnualKonvensionalFinancial = 0;
		this._arrayPageSizeAnnualKonvensionalFinancial = [];
		this._numberPageSizeAnnualKonvensionalFinancial = Math.ceil(this._arrayModelAnnualKonvensionalFinancial.length/this._numberConstant.NUMBER_COMPARE_VALUE_THREE);

		this._numberIndexQuarterlyReports = 0;
		this._arrayPageSizeQuarterlyReports = [];
		this._numberPageSizeQuarterlyReports = Math.ceil(this._arrayModelQuarterlyReports.length/this._numberConstant.NUMBER_COMPARE_VALUE_NINE);

		this._numberIndexQuarterlyReportsSyariah = 0;
		this._arrayPageSizeQuarterlyReportsSyariah = [];
		this._numberPageSizeQuarterlyReportsSyariah = Math.ceil(this._arrayModelQuarterlyReportsSyariah.length/this._numberConstant.NUMBER_COMPARE_VALUE_NINE);

	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.createPageSizeQuarterlyReports();
		this.createPageSizeQuarterlyReportsSyariah();
		this.createPageSizeAnnualKonvensionalFinancial();
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	downloadFile(stringPath: string): void
	{
		window.open(stringPath, "_blank");
	}

	goToAnnualFinancial(enumEconomicValueTyoe: ENUM_ECONOMICVALUE_TYPE): void
	{
		this._numberAnnualFinancialValueTypeActive = enumEconomicValueTyoe;
	}

	goToQuarterlyReports(enumEconomicValueTyoe: ENUM_ECONOMICVALUE_TYPE): void
	{
		this._numberQuarterlyReportsValueTypeActive = enumEconomicValueTyoe;
	}

	trackAnnualFinancial(index, modelAnnualFinancial: ContentModel)
	{
		return modelAnnualFinancial ? modelAnnualFinancial.ID : undefined;
	}

	trackQuarterlyReports(index, modelQuarterlyReports: ContentModel)
	{
		return modelQuarterlyReports ? modelQuarterlyReports.ID : undefined;
	}

	//#region Annual Konvensional

	createPageSizeAnnualKonvensionalFinancial(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeAnnualKonvensionalFinancial; numberPageSize++)
		{
			this._arrayPageSizeAnnualKonvensionalFinancial.push(numberPageSize);
		}
	}

	paginateAnnualFinancialKonvensionalModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;
		return this._arrayModelAnnualKonvensionalFinancial.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateAnnualKonvensionalFinancial(numberPaginate): void
	{
		this._numberIndexAnnualKonvensionalFinancial = numberPaginate;
	}

	//#endregion

	//#region QuarterlyReports Konvensional

	createPageSizeQuarterlyReports(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeQuarterlyReports; numberPageSize++)
		{
			this._arrayPageSizeQuarterlyReports.push(numberPageSize);
		}
	}

	paginateQuarterlyReportsModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_NINE;
		return this._arrayModelQuarterlyReports.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateQuarterlyReports(numberPaginate): void
	{
		this._numberIndexQuarterlyReports = numberPaginate;
	}

	//#endregion


	//#region QuarterlyReports Syariah

	createPageSizeQuarterlyReportsSyariah(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeQuarterlyReportsSyariah; numberPageSize++)
		{
			this._arrayPageSizeQuarterlyReportsSyariah.push(numberPageSize);
		}
	}

	paginateQuarterlyReportsSyariahModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_NINE;
		return this._arrayModelQuarterlyReportsSyariah.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateQuarterlyReportsSyariah(numberPaginate): void
	{
		this._numberIndexQuarterlyReportsSyariah = numberPaginate;
	}

	searchByValue(): void
	{
		this._modelContentTemporary = [];
		if(this._stringInputValue !== null && this._stringInputValue !== undefined && this._stringInputValue !== "")
		{
			let arrayContentTemporary: Array<ContentModel> = this._arrayModelAnnualKonvensionalFinancial.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayContentTemporary.forEach(element =>
			{
				this._modelContentTemporary.push(element);
			});

			arrayContentTemporary = this._arrayModelQuarterlyReports.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayContentTemporary.forEach(element =>
			{
				this._modelContentTemporary.push(element);
			});

			arrayContentTemporary = this._arrayModelQuarterlyReportsSyariah.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayContentTemporary.forEach(element =>
			{
				this._modelContentTemporary.push(element);
			});
		}

	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	//#endregion


	//#endregion


	//#region NAVIGATION

	goToDirectors(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "directors"]);
	}

	goToCommissioners(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "commissioner"]);
	}

	goToDivision(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "division"]);
	}

	//#endregion

}
