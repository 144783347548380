//#region CLASS

import { ENUM_MARITAL_STATUS } from "../constants/enum.constant";

export class MaritaStatuslModel
{
	/*
		Attribute - START
		Description : Attribute for marital model.
		Author: Dimas Dandy.
		Created on : Tuesday, 13 August 2024	    	Updated on : .
		Created by : Dimas Dandy.						Updated by : .
		Version : 1.0:1.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	MaritalStatus?: ENUM_MARITAL_STATUS;

	/* Attribute - END */
}

//#endregion