//#region IMPORT

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_CITIZENSHIP_STATUS, ENUM_GENDER_TYPE, ENUM_MARITAL_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { AverageIncomePerYearModel } from "src/app/models/averageincomeperyear.model";
import { TableModel } from "src/app/models/bases/table.model";
import { BranchModel } from "src/app/models/branch.model";
import { BusinessRelationshipModel } from "src/app/models/businessrelationship.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { InsurancePurposeModel } from "src/app/models/insurancepurpose.model";
import { LocationModel } from "src/app/models/location.model";
import { MaritaStatuslModel } from "src/app/models/maritalstatus.model";
import { OccupationModel } from "src/app/models/occupation.model";
import { PostalModel } from "src/app/models/postal.model";
import { ResponseModel } from "src/app/models/response.model";
import { SourceOfIncomeModel } from "src/app/models/sourceofincome.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { OccuppationService } from "src/app/services/occupation.service";
import { OptionService } from "src/app/services/option.service";
import { PostalService } from "src/app/services/postal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion



//#region COMPONENT

@Component
(
	{
		selector: "app-signup",
		templateUrl: "./signup.component.html",
		styleUrls: ["./signup.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SignupComponent extends BaseAuthourizeDetailComponent implements OnInit
{
	//#region DECLARATION

	public _modelUserPublic: UserModel;
	public _modelUserPublicRequest: UserModel;
	public _modelPostal: PostalModel;
	public _modelBranch: BranchModel;
	public _modelTable: TableModel;
	public _modelOccupation: OccupationModel;
	public _modelSourceOfIncome: SourceOfIncomeModel;
	public _modelAverageIncomePerYear: AverageIncomePerYearModel;
	public _modelBusinessRelationship: BusinessRelationshipModel;
	public _modelInsurancePurpose: InsurancePurposeModel;
	public _modelMaritalStatus: MaritaStatuslModel;

	public _numberConstant = NumberConstant;

	public _booleanFormSignInEmail: boolean;
	public _booleanFormGender: boolean;
	public _booleanPostalCodeValid: boolean;

	public _stringConstant = StringConstant;
	public _stringDownloadCategory: string = "";

	public _enumGenderType = ENUM_GENDER_TYPE;
	public _enumCitizenship = ENUM_CITIZENSHIP_STATUS;
	public _enumMaritalStatus = ENUM_MARITAL_STATUS;

	public _functionRegex: RegexFunction;

	public _dateNow: Date;

	public _arrayStringURL: Array<string> = [];
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];
	public _arrayModelBranch: Array<BranchModel>;
	public _arrayModelOccupation: Array<OccupationModel>;
	public _arrayModelSourceOfIncome: Array<SourceOfIncomeModel>;
	public _arrayModelAverageIncomePerYear: Array<AverageIncomePerYearModel>;
	public _arrayModelBusinessRelationship: Array<BusinessRelationshipModel>;
	public _arrayModelInsurancePurpose: Array<InsurancePurposeModel>;
	public _arrayModelMaritalStatus: Array<MaritaStatuslModel>;
	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, routeActivated: ActivatedRoute, location: Location, private _servicePostal: PostalService, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService, private _serviceOccupation: OccuppationService, private _serviceOption: OptionService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicRequest = new UserModel();
		this._modelPostal = new PostalModel();
		this._modelBranch = new BranchModel();
		this._modelTable = new TableModel();
		this._modelOccupation = new OccupationModel();
		this._modelSourceOfIncome = new SourceOfIncomeModel();
		this._modelAverageIncomePerYear = new AverageIncomePerYearModel();
		this._modelBusinessRelationship = new BusinessRelationshipModel();
		this._modelInsurancePurpose = new InsurancePurposeModel();
		this._modelMaritalStatus = new MaritaStatuslModel();
		this._modelUserPublic.ProductPromotionConsent = false;
		this._modelUserPublic.DataSharingConsent = false;
		this._modelSourceOfIncome.Name = StringConstant.STRING_OPTION_FORM_USER_SOURCEOFINCOOME;
		this._modelMaritalStatus.Name = StringConstant.STRING_OPTION_FORM_USER_MARITALSTATUS;
		this._modelAverageIncomePerYear.Name = StringConstant.STRING_OPTION_FORM_USER_AVERAGEINCOME;
		this._modelBranch.Name = StringConstant.STRING_OPTION_FORM_USER_BRANCH;
		this._modelInsurancePurpose.Name = StringConstant.STRING_OPTION_FORM_USER_BUSINESSPURPOSE;
		this._modelOccupation.Name = StringConstant.STRING_OPTION_FORM_USER_OCCUPATION;

		this._booleanFormSignInEmail = true;
		this._booleanFormGender = true;
		this._booleanPostalCodeValid = false;

		this._arrayModelBranch = [];
		this._arrayModelOccupation = [];
		this._arrayModelSourceOfIncome = [];
		this._arrayModelAverageIncomePerYear = [];
		this._arrayModelBusinessRelationship = [];
		this._arrayModelInsurancePurpose = [];
		this._arrayModelMaritalStatus =
		[
			{
				Name: "Lajang", MaritalStatus: ENUM_MARITAL_STATUS.Single
			},
			{
				Name: "Menikah", MaritalStatus: ENUM_MARITAL_STATUS.Married
			},
			{
				Name: "Cerai", MaritalStatus: ENUM_MARITAL_STATUS.Divorce
			},
			{
				Name: "Duda/Janda", MaritalStatus: ENUM_MARITAL_STATUS.Widow
			}
		];

		this._dateNow = new Date();

		this._functionRegex = new RegexFunction();

	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._serviceCustomer._modelUserObservable.subscribe((modelUser) =>
		{
			this._modelUserPublic.Email = modelUser.Email;
			this._modelUserPublic.MobilePhone = modelUser.MobilePhone;

			if (this._modelUserPublic.Email === null || this._modelUserPublic.Email === undefined || this._modelUserPublic.MobilePhone === null || this._modelUserPublic.MobilePhone === undefined)
			{
				this._router.navigate(["signin"]);
			}
			else
			{
				this.callSelectBranchForCustomer();
			}
		});
	}

	//#endregion


	//#region WEB SERVICE

	private callUpdateCustomerPersonalData(componentCurrent: this): void
	{
		this._modelUserPublic.modelDivision = undefined;
		this._modelUserPublic.MobilePhone = this._modelUserPublic.MobilePhone?.toString();
		this._modelUserPublic.setMobilePhonePrefixIndonesian();

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		let modelResponseValidation: ResponseModel = this._modelUserPublic.validateFormUser();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = this._modelUserPublic.validateFormUser();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.updateCustomerPersonalData
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});

							const modelUserResponse: UserModel = new UserModel();
							modelUserResponse.setModelFromString(modelResponse.Data);
							modelUserResponse.modelDivision = undefined;

							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);

							componentCurrent._router.navigate(["home"]);
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserPublic);
		}
	}

	private callSelectLocationByPostalCode(): void
	{
		const componentCurrent: SignupComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		if (componentCurrent._modelUserPublic.PostalCode !== null && componentCurrent._modelUserPublic.PostalCode !== undefined)
		{
			componentCurrent._modelPostal.Code = componentCurrent._modelUserPublic.PostalCode;
		}
		else
		{

		}

		this._servicePostal.selectLocationByPostalCodeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelLocationResponse: LocationModel = new LocationModel();
						modelLocationResponse.setModelFromString(modelResponse.Data);

						componentCurrent._modelUserPublic.setLocationFromPostal(modelLocationResponse);
						componentCurrent._booleanPostalCodeValid = true;
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
					{
						componentCurrent._modelUserPublic.CountryName = "";
						componentCurrent._modelUserPublic.ProvinceName = "";
						componentCurrent._modelUserPublic.CityName = "";
					});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectLocationByPostalCode(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelPostal);
	}

	private callSelectBranchForCustomer(): void
	{
		const componentCurrent: SignupComponent = this;
		componentCurrent._modelTable.Search = JSON.stringify(this._modelUserPublic.BranchName);
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		this._modelTable.RowPerPage = 100;

		this._serviceBranch.selectBranchForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectOccupationForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelTable);
	}

	private callSelectOccupationForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOccupation.selectOccupationForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelOccupation = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectSourceOfIncomeForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectOccupationForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectSourceOfIncomeForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectSourceOfIncomeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelSourceOfIncome = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectAverageIncomePerYearForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectSourceOfIncomeForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectAverageIncomePerYearForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectAverageIncomePerYearForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelAverageIncomePerYear = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectBusinessRelationshipForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAverageIncomePerYearForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectBusinessRelationshipForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectBusinessRelationshipForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelBusinessRelationship = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent.callSelectInsurancePurposeForCustomer(componentCurrent);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBusinessRelationshipForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectInsurancePurposeForCustomer(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTMAXTABLE;

		componentCurrent._serviceOption.selectInsurancePurposeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelInsurancePurpose = JSON.parse(modelTableResponse.Result);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectInsurancePurposeForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}

		return stringEmailToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	getBirthDate(event): void
	{
		const maxDate: Date = this._dateNow;
		maxDate.setDate(maxDate.getDate() + 1);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Tanggal Lahir";
		modelResponse.MessageContent = "Tanggal lahir tidak boleh lebih dari hari ini!";

		if(event.value > maxDate)
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			this._modelUserPublic.BirthDate = event.value.clearUTC();
		}
	};

	public getMaritalStatus(enumMaritalStatus: ENUM_MARITAL_STATUS): string
	{
		if (enumMaritalStatus === ENUM_MARITAL_STATUS.Single)
		{
			return "Lajang";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Married)
		{
			return "Menikah";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Divorce)
		{
			return "Cerai";
		}
		else if (enumMaritalStatus === ENUM_MARITAL_STATUS.Widow)
		{
			return "Duda / Janda";
		}
		else
		{
			return "Pilih Status Perkawinan";
		}
	}

	//#endregion


	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	selectPostalCode(): void
	{
		if(this._modelUserPublic.PostalCode?.length === this._numberConstant.NUMBER_COMPARE_VALUE_FIVE)
		{
			this.callSelectLocationByPostalCode();
		}
		else
		{

		}
	}

	//#endregion


	//#region FUNCTION

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];

		return stringToken;
	}

	callSumbitSignUp()
	{
		const stringMessage = "Sign Up\nApakah data yang Anda masukkan sudah benar?";

		if (confirm(stringMessage))
		{
			this.callUpdateCustomerPersonalData(this);
		}
		else
		{
		}
	}

	checkValidationFormUser()
	{
		this._modelUserPublic.validateFormUser();
	}

	//#endregion


	//#region SETTER

	setGender(enumGender: ENUM_GENDER_TYPE): void
	{
		if(enumGender !== undefined)
		{
			this._modelUserPublic.Gender = enumGender;
		}
	}

	setCitizenship(enumCitizenship: ENUM_CITIZENSHIP_STATUS): void
	{
		if(enumCitizenship !== undefined)
		{
			this._modelUserPublic.CitizenshipStatus = enumCitizenship;
		}
	}

	setBranch(modelBranch: BranchModel): void
	{
		this._modelUserPublic.BranchID = modelBranch.ID;
		this._modelUserPublic.BranchCode = modelBranch.Code;
		this._modelUserPublic.BranchName = modelBranch.Name;
	}

	setOccupation(modelOccupation: OccupationModel): void
	{
		this._modelUserPublic.OccupationCode = modelOccupation.Code;
		this._modelUserPublic.OccupationName = modelOccupation.Name;
	}

	setAverageIncomePerYear(modelAverageIncomePerYear: AverageIncomePerYearModel): void
	{
		this._modelUserPublic.AverageIncomePerYearCode = modelAverageIncomePerYear.Code;
		this._modelUserPublic.AverageIncomePerYearName = modelAverageIncomePerYear.Name;
	}

	setSourceOfIncome(modelSourceOfIncome: SourceOfIncomeModel): void
	{
		this._modelUserPublic.SourceOfIncomeCode = modelSourceOfIncome.Code;
		this._modelUserPublic.SourceOfIncomeName = modelSourceOfIncome.Name;
	}

	setBusinessRelationship(modelBusinessRelationship: BusinessRelationshipModel): void
	{
		this._modelUserPublic.BusinessRelationshipCode = modelBusinessRelationship.Code;
		this._modelUserPublic.BusinessRelationshipName = modelBusinessRelationship.Name;
	}

	setInsurancePurpose(modelInsurancePurpose: InsurancePurposeModel): void
	{
		this._modelUserPublic.InsurancePurposeCode = modelInsurancePurpose.Code;
		this._modelUserPublic.InsurancePurposeName = modelInsurancePurpose.Name;
	}

	setMaritalStatus(modelMaritalStatus: MaritaStatuslModel): void
	{
		this._modelUserPublic.MaritalStatus = modelMaritalStatus.MaritalStatus;
	}

	setBooleanDataSharingConsent(event): void
	{
		this._modelUserPublic.DataSharingConsent = event.checked;
	}

	setBooleanProductPromotionConsent(event): void
	{
		this._modelUserPublic.ProductPromotionConsent = event.checked;
	}

	//#endregion
}

//#endregion